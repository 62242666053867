import project1 from "../Asserts/Images/sk (1).webp";
import project2 from "../Asserts/Images/sk (2).webp"
import project3 from "../Asserts/Images/sk (3).webp"
import project4 from "../Asserts/Images/sk (4).webp"
import project5 from "../Asserts/Images/sk (5).webp"

import project8 from "../Asserts/Images/sk (8).webp"
import project9 from "../Asserts/Images/sk (9).webp"
import project10 from "../Asserts/Images/sk (10).webp"
import project12 from "../Asserts/Images/sk (12).webp"
import project13 from "../Asserts/Images/sk (13).webp"
import project14 from "../Asserts/Images/sk (14).webp"
import project15 from "../Asserts/Images/sk (15).webp"
import project16 from "../Asserts/Images/sk (16).webp"
import project17 from "../Asserts/Images/sk (17).webp"
import project18 from "../Asserts/Images/sk (18).webp"
import project19 from "../Asserts/Images/sk (19).webp"
import project20 from "../Asserts/Images/sk (20).webp"
import project21 from "../Asserts/Images/sk (21).webp"
import project22 from "../Asserts/Images/sk (22).webp"
import project23 from "../Asserts/Images/sk (23).webp"
import project26 from "../Asserts/Images/sk (26).webp"
import project27 from "../Asserts/Images/sk (27).webp"
import project28 from "../Asserts/Images/sk (28).webp"

const projectData = [
    {
      headName: "Project 1",
      img: project1,
      altTag: "Image 1",
    },
    {
      headName: "Project 2",
      img: project2,
      altTag: "Image 2",
    },
    {
      headName: "Project 3",
      img: project3,
      altTag: "Image 3",
    },
    {
      headName: "Project 4",
      img: project4,
      altTag: "Image 4",
    },
    {
      headName: "Project 5",
      img: project5,
      altTag: "Image 5",
    },
    // {
    //   headName: "Project 6",
    //   img: project6,
    //   altTag: "Image 6",
    // },
    // {
    //   headName: "Project 7",
    //   img: project7,
    //   altTag: "Image 7",
    // },
    {
      headName: "Project 8",
      img: project8,
      altTag: "Image 8",
    },
    {
      headName: "Project 9",
      img: project9,
      altTag: "Image 9",
    },
    {
      headName: "Project 10",
      img: project10,
      altTag: "Image 10",
    },
    // {
    //   headName: "Project 11",
    //   img: project11,
    //   altTag: "Image 11",
    // },
    {
      headName: "Project 12",
      img: project12,
      altTag: "Image 12",
    },
    {
      headName: "Project 13",
      img: project13,
      altTag: "Image 13",
    },
    {
      headName: "Project 14",
      img: project14,
      altTag: "Image 14",
    },
    {
      headName: "Project 15",
      img: project15,
      altTag: "Image 15",
    },
    {
      headName: "Project 16",
      img: project16,
      altTag: "Image 16",
    },
    {
      headName: "Project 17",
      img: project17,
      altTag: "Image 17",
    },
    {
      headName: "Project 18",
      img: project18,
      altTag: "Image 18",
    },
    {
      headName: "Project 19",
      img: project19,
      altTag: "Image 19",
    },
    {
      headName: "Project 20",
      img: project20,
      altTag: "Image 20",
    },
    {
      headName: "Project 21",
      img: project21,
      altTag: "Image 21",
    },
    {
      headName: "Project 22",
      img: project22,
      altTag: "Image 22",
    },
    {
      headName: "Project 23",
      img: project23,
      altTag: "Image 23",
    },
    {
      headName: "Project 26",
      img: project26,
      altTag: "Image 26",
    },
    {
      headName: "Project 27",
      img: project27,
      altTag: "Image 27",
    },
    {
      headName: "Project 28",
      img: project28,
      altTag: "Image 28",
    },
    {
      headName: "Project 1",
      img: project1,
      altTag: "Image 1",
    },
  ];
export default projectData;  