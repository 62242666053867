import React, { useState, useLayoutEffect } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import "../Css/Gallery.css";
import MetaTags from "react-meta-tags";

import { TiEyeOutline } from "react-icons/ti";
import project1 from "../Asserts/Images/sk (1).webp";
import project2 from "../Asserts/Images/sk (2).webp";
import project3 from "../Asserts/Images/sk (3).webp";
import project4 from "../Asserts/Images/sk (4).webp";
import project5 from "../Asserts/Images/sk (5).webp";
// import project6 from "../Asserts/Images/sk (6).webp"
import project7 from "../Asserts/Images/sk (7).webp";
import project8 from "../Asserts/Images/sk (8).webp";
// import project9 from "../Asserts/Images/sk (9).webp"
// import project10 from "../Asserts/Images/sk (10).webp"
import project11 from "../Asserts/Images/sk (11).webp";
import project12 from "../Asserts/Images/sk (12).webp";
import project13 from "../Asserts/Images/sk (13).webp";
import project14 from "../Asserts/Images/sk (14).webp";
import project15 from "../Asserts/Images/sk (15).webp";
import project16 from "../Asserts/Images/sk (16).webp";
import project17 from "../Asserts/Images/sk (17).webp";
import project18 from "../Asserts/Images/sk (18).webp";
import project19 from "../Asserts/Images/sk (19).webp";
import project20 from "../Asserts/Images/sk (20).webp";
import project21 from "../Asserts/Images/sk (21).webp";
import project22 from "../Asserts/Images/sk (22).webp";
import project23 from "../Asserts/Images/sk (23).webp";
import project26 from "../Asserts/Images/sk (26).webp";
import project27 from "../Asserts/Images/sk (27).webp";
import project28 from "../Asserts/Images/sk (28).webp";

const Gallery = ({ bannerImage }) => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  // ========== Smile Gallery ========
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const images = [
    // project11,
    project12,
    project13,
    project14,
    project15,
    project16,
    project17,
    project18,
    project19,
    project20,
    project21,
    project22,
    project23,
    project26,
    project27,
    project28,
    project1,
    project2,
    project3,
    project4,
    project5,
    project7,
    project8,
  ];

  const openLightbox = (index) => {
    setPhotoIndex(index);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
  };
  return (
    <>
      <MetaTags>
        <title>3D Wallpaper For Wall In Santacruz | SK-Wallpaper</title>
        <meta title="3D Wallpaper For Wall In Santacruz | SK-Wallpaper" />
        <meta
          name="description"
          content="Discover exquisite 3D wallpaper for walls in Santacruz by SK-Wallpaper, enhancing spaces with immersive depth and captivating designs."
        />
        <meta
          name="keywords"
          content="Wallpaper For Walls In Santacruz ,Designer Wallpaper In Santacruz ,Rooms Wallpaper In Santacruz,Glass Flims Wallpaper Service In santacruz,3D Wallpaper For Wall In Santacruz,Wallpaper Store Near Santacruz"
        />
        <link
          rel="canonical"
          href="https://skdecors.com/3d-wallpaper-for-wall-in-santacruz"
        />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta
          property="og:title"
          content="3D Wallpaper For Wall In Santacruz | SK-Wallpaper"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://skdecors.com/3d-wallpaper-for-wall-in-santacruz"
        />
        <meta
          property="og:description"
          content="Discover exquisite 3D wallpaper for walls in Santacruz by SK-Wallpaper, enhancing spaces with immersive depth and captivating designs."
        />
        <meta
          property="og:image"
          content="https://skdecors.com/static/media/JMS.7493f202fc387288d7c0.png"
        />
      </MetaTags>
      <section className="page-header @@ExtraClass">
        <div
          className="page-header__bg"
          style={{
            backgroundImage: `url("${bannerImage}")`,
          }}
          bis_skin_checked={1}
        />
        {/* /.page-header__bg */}
        <div className="container" bis_skin_checked={1}>
          <ul className="wallpi-breadcrumb list-unstyled">
            <li>
              <a href="index.html" bis_skin_checked={1}>
                Home
              </a>
            </li>
            <li>
              <span>Gallery</span>
            </li>
          </ul>
          {/* /.thm-breadcrumb list-unstyled */}
          <h1 className="page-header__title">
            3D Wallpaper For Wall In Santacruz{" "}
          </h1>
        </div>
        {/* /.container */}
      </section>
      <section className="smile-section">
        <div className="container">
          {/* <h2 className="text-center text-black">Products</h2> */}

          <p className="d-none">
            Introducing our exceptional range of 3D Wallpaper For Wall In
            Santacruz, where creativity meets innovation to redefine your living
            spaces. Elevate your interiors with the captivating allure of 3D
            Wallpaper For Wall In Santacruz, a perfect blend of visual appeal
            and modern aesthetics. At 3D Wallpaper For Wall In Santacruz, our
            commitment is to bring your walls to life with stunning
            three-dimensional designs that transform ordinary spaces into
            extraordinary expressions of style. Explore the world of
            possibilities with 3D Wallpaper For Wall In Santacruz, where every
            pattern and texture tells a unique story. Immerse yourself in the
            immersive experience offered by 3D Wallpaper For Wall In Santacruz,
            adding depth and character to your home or office. With 3D Wallpaper
            For Wall In Santacruz, your walls become a canvas for artistic
            expression, allowing you to personalize your space like never
            before.
          </p>
          <div className="sec-title centered">
            {/* <h2>Photo Gallery</h2>
      <div className="lower-text">
      Welcome to our resort in neral for stay ,where luxury meets tranquility. Explore our pool, restaurant, and diverse recreational activities. At our resort in neral for stay, every detail is crafted to ensure your stay is a seamless blend of comfort and sophistication. Your escape to serenity begins here.

      </div> */}
          </div>
          <div className="row row-cols-md-4 row-cols-sm-2 row-cols-1">
            {images.map((image, index) => (
              <div className="col mb-md-5 mb-3 image-card" key={index}>
                {/* <Bounce> */}
                <div className="smile-img" onClick={() => openLightbox(index)}>
                  <img src={image} alt="3D Wallpaper For Wall In Santacruz" />
                  <div className="smile-icon">
                    <TiEyeOutline />
                  </div>
                </div>
                {/* </Bounce> */}
              </div>
            ))}
            {lightboxOpen && (
              <Lightbox
                mainSrc={images[photoIndex]}
                nextSrc={images[(photoIndex + 1) % images.length]}
                prevSrc={
                  images[(photoIndex + images.length - 1) % images.length]
                }
                onCloseRequest={closeLightbox}
                onMovePrevRequest={() =>
                  setPhotoIndex(
                    (photoIndex + images.length - 1) % images.length
                  )
                }
                onMoveNextRequest={() =>
                  setPhotoIndex((photoIndex + 1) % images.length)
                }
              />
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Gallery;
