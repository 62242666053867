import './App.css';
// import Navbars from './Component/Navbars';
import Headers from "./Component/Header";
import Home from './Component/Home';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import About from './Pages/About';
import Footer from './Component/Footer';
import Services from './Pages/Services';
import Contact from './Pages/Contact';
import bannerImage from "../src/Asserts/Images/hero-image2-1.webp";
import Products from './Pages/Products';
import Gallery from "./Pages/Gallery";
import ErrorPage from "./Pages/ErrorPage";

function App() {
  return (
    <>
      {/* <Navbar /> */}
      <BrowserRouter>
      <Headers />
        <Routes>
          <Route path='/' element={<Home/>}  />
          <Route path='/designer-wallpaper-in-santacruz' element={<About bannerImage={bannerImage}t />}  />
          <Route path='/rooms-wallpaper-in-santacruz' element={<Services bannerImage={bannerImage} />} />
          <Route path='/glass-flims-wallpaper-service-in-santacruz' element={<Products bannerImage={bannerImage} />} />
          <Route path='/wallpaper-store-near-santacruz' element={<Contact bannerImage={bannerImage} />}  />
          <Route path='/3d-wallpaper-for-wall-in-santacruz' element= {<Gallery bannerImage={bannerImage} />} />
          <Route path='/*' element= {<ErrorPage />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
