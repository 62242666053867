import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";

import { RiDoubleQuotesL } from "react-icons/ri";
import user from "../Asserts/Images/img_avatar3.webp";

const TestimonialSlider = () => {
  return (
    <>
      <Swiper
        slidesPerView={1}
        spaceBetween={10}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 50,
          },
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        {/* {serviceData.map((v,i)=>{ */}
        <SwiperSlide>
          <div
            className="testimonials-card wow fadeInUp animated"
            data-wow-duration="1500ms"
            data-wow-delay="000ms"
            style={{
              visibility: "visible",
              animationDuration: "1500ms",
              animationDelay: "0ms",
              animationName: "fadeInUp",
            }}
            bis_skin_checked={1}
          >
            <div className="testimonials-card__inner" bis_skin_checked={1}>
              <div
                className="testimonials-card__top d-flex align-items-center justify-content-between"
                bis_skin_checked={1}
              >
                <i className="icon-quote testimonials-card__quote">
                  {" "}
                  <RiDoubleQuotesL />
                </i>
                <div className="testimonials-card__rating" bis_skin_checked={1}>
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                </div>
              </div>
              <div className="testimonials-card__content" bis_skin_checked={1}>
                Mr. Anwar Ansari is very professional with his work, right from
                consultation to finishing. Got wallpaper done by him, he
                finished it in time. The job was neat and well done.Thank you so
                much Mr. Ansari.
              </div>
              <div
                className="testimonials-card__athour-wrap d-flex align-items-center"
                bis_skin_checked={1}
              >
                <div className="testimonials-card__image" bis_skin_checked={1}>
                  <img src={user} alt="Robin Kaolaip" />
                </div>
                <div
                  className="testimonials-card__athour-name"
                  bis_skin_checked={1}
                >
                  <h5 className="testimonials-card__name"> Astor Rodrigues</h5>
                  {/* <p className="testimonials-card__designation">
          Interior design, architecture
        </p> */}
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div
            className="testimonials-card wow fadeInUp animated"
            data-wow-duration="1500ms"
            data-wow-delay="000ms"
            style={{
              visibility: "visible",
              animationDuration: "1500ms",
              animationDelay: "0ms",
              animationName: "fadeInUp",
            }}
            bis_skin_checked={1}
          >
            <div className="testimonials-card__inner" bis_skin_checked={1}>
              <div
                className="testimonials-card__top d-flex align-items-center justify-content-between"
                bis_skin_checked={1}
              >
                <i className="icon-quote testimonials-card__quote">
                  {" "}
                  <RiDoubleQuotesL />
                </i>
                <div className="testimonials-card__rating" bis_skin_checked={1}>
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                </div>
              </div>
              <div className="testimonials-card__content" bis_skin_checked={1}>
                SK decor completed a wallpaper job for my apartment last week.
                Mr. Anwar displayed professionalism in his attitude & work. We
                are overall satisfied and I recommend him for your home decor
                needs.
              </div>
              <div
                className="testimonials-card__athour-wrap d-flex align-items-center"
                bis_skin_checked={1}
              >
                <div className="testimonials-card__image" bis_skin_checked={1}>
                  <img src={user} alt="Robin Kaolaip" />
                </div>
                <div
                  className="testimonials-card__athour-name"
                  bis_skin_checked={1}
                >
                  <h5 className="testimonials-card__name"> Anil Sharma</h5>
                  {/* <p className="testimonials-card__designation">
          Interior design, architecture
        </p> */}
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            className="testimonials-card wow fadeInUp animated"
            data-wow-duration="1500ms"
            data-wow-delay="000ms"
            style={{
              visibility: "visible",
              animationDuration: "1500ms",
              animationDelay: "0ms",
              animationName: "fadeInUp",
            }}
            bis_skin_checked={1}
          >
            <div className="testimonials-card__inner" bis_skin_checked={1}>
              <div
                className="testimonials-card__top d-flex align-items-center justify-content-between"
                bis_skin_checked={1}
              >
                <i className="icon-quote testimonials-card__quote">
                  {" "}
                  <RiDoubleQuotesL />
                </i>
                <div className="testimonials-card__rating" bis_skin_checked={1}>
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                </div>
              </div>
              <div className="testimonials-card__content" bis_skin_checked={1}>
                It was an amazing experience right from the selection to
                implementation of the work within the stipulated period of time
                We did t even know when was the last moment of completion. Worth
                the time spent
              </div>
              <div
                className="testimonials-card__athour-wrap d-flex align-items-center"
                bis_skin_checked={1}
              >
                <div className="testimonials-card__image" bis_skin_checked={1}>
                  <img src={user} alt="Robin Kaolaip" />
                </div>
                <div
                  className="testimonials-card__athour-name"
                  bis_skin_checked={1}
                >
                  <h5 className="testimonials-card__name"> Dwayne Godinho</h5>
                  {/* <p className="testimonials-card__designation">
          Interior design, architecture
        </p> */}
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            className="testimonials-card wow fadeInUp animated"
            data-wow-duration="1500ms"
            data-wow-delay="000ms"
            style={{
              visibility: "visible",
              animationDuration: "1500ms",
              animationDelay: "0ms",
              animationName: "fadeInUp",
            }}
            bis_skin_checked={1}
          >
            <div className="testimonials-card__inner" bis_skin_checked={1}>
              <div
                className="testimonials-card__top d-flex align-items-center justify-content-between"
                bis_skin_checked={1}
              >
                <i className="icon-quote testimonials-card__quote">
                  {" "}
                  <RiDoubleQuotesL />
                </i>
                <div className="testimonials-card__rating" bis_skin_checked={1}>
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                </div>
              </div>
              <div className="testimonials-card__content" bis_skin_checked={1}>
                This is one of the best interior decorator specially mr Anwar is
                very much experienced and loyal to his work I would recommend
                him to all my family and friends to take his help for wallpapers
                and all home decorations
              </div>
              <div
                className="testimonials-card__athour-wrap d-flex align-items-center"
                bis_skin_checked={1}
              >
                <div className="testimonials-card__image" bis_skin_checked={1}>
                  <img src={user} alt="Robin Kaolaip" />
                </div>
                <div
                  className="testimonials-card__athour-name"
                  bis_skin_checked={1}
                >
                  <h5 className="testimonials-card__name"> esa khan</h5>
                  {/* <p className="testimonials-card__designation">
          Interior design, architecture
        </p> */}
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            className="testimonials-card wow fadeInUp animated"
            data-wow-duration="1500ms"
            data-wow-delay="000ms"
            style={{
              visibility: "visible",
              animationDuration: "1500ms",
              animationDelay: "0ms",
              animationName: "fadeInUp",
            }}
            bis_skin_checked={1}
          >
            <div className="testimonials-card__inner" bis_skin_checked={1}>
              <div
                className="testimonials-card__top d-flex align-items-center justify-content-between"
                bis_skin_checked={1}
              >
                <i className="icon-quote testimonials-card__quote">
                  {" "}
                  <RiDoubleQuotesL />
                </i>
                <div className="testimonials-card__rating" bis_skin_checked={1}>
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                </div>
              </div>
              <div className="testimonials-card__content" bis_skin_checked={1}>
                SK Decors have a huge experience in this field with end number
                of outstanding collections. Anwar Ansari is gentle, soft spoken,
                & down to earth. On cost he compromises to the extent possible.
                Wish him good luck in this business.
              </div>
              <div
                className="testimonials-card__athour-wrap d-flex align-items-center"
                bis_skin_checked={1}
              >
                <div className="testimonials-card__image" bis_skin_checked={1}>
                  <img src={user} alt="Robin Kaolaip" />
                </div>
                <div
                  className="testimonials-card__athour-name"
                  bis_skin_checked={1}
                >
                  <h5 className="testimonials-card__name">
                    {" "}
                    Chaitali Banerjee
                  </h5>
                  {/* <p className="testimonials-card__designation">
          Interior design, architecture
        </p> */}
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
};

export default TestimonialSlider;
