import React, {useRef, useState} from 'react';
import Modal from 'react-modal';
import $ from "jquery";
import { Button, Form, Container } from "react-bootstrap";

const ContactModal = () => {
   // email
 const formRef = useRef(null);
 const [custName, setcustName] = useState("");
 const [custContact, setCustContact] = useState("");
 const [custMessage, setCustMessage] = useState("");
 const [custEmail, setCustEmail] = useState("");
 const [CustPincode, setCustPincode] = useState("");
 const [formError, setFormError] = useState(false);

 const ContactEnquiry = (e) => {
   e.preventDefault();

   // Validate form fields
   if (!custName || !custContact || !custEmail || !CustPincode ) {
     setFormError(true);
     return;
   }

   // Reset form error state
   setFormError(false);

   // Your form submission logic goes here
   var body = `<!DOCTYPE html><html><head><title>Enquiry Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style="color:#659494">SK Decor</h2><p>Hello</p><p>Please check your enquiry which generated from the website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left"><strong>Name:</strong></td><td style="text-align:left">${custName}</td></tr><tr><td style="text-align:left"><strong>Email:</strong></td><td style="text-align:left">${custEmail}</td></tr><tr><td style="text-align:left"><strong>Contact:</strong></td><td style="text-align:left">${custContact}</td></tr><tr><td style="text-align:left"><strong>Pincode:</strong></td><td style="text-align:left">${CustPincode}</td></tr><tr><td style="text-align:left;vertical-align:top"><strong>Message:</strong></td><td style="text-align:left">${custMessage}</td></tr></table><p style="font-weight:700">Best regards,<br>Your Team at Sk Decor</p></div></body></html>`

   $.post(
     "https://skdm.in/server/v1/send_lead_mail.php",
     {
       toEmail: "skdecor1968@gmail.com",
       fromEmail: "skdmlead@gmail.com",
       bccMail: "skdmlead@gmail.com",
       mailSubject: "New Customer Lead",
       mailBody: body,
       accountName: "skdecor",
       accountLeadSource: "https://skdm.in",
       accountLeadName: custName,
     },
     function (dataa, status) {
       console.log("data :" + dataa);
     }
   );

   // Reset the form
   if (formRef.current) {
     formRef.current.reset();
   }

   alert("Your Form has Submitted. Our team will contact you soon.");

 };
    
  return (
    <Container>
    <form
    method="post"
                  ref={formRef}
                  action=""
                  id="contact-form">
    <div className="row" bis_skin_checked={1}>
                <div className="col-md-6" bis_skin_checked={1}>
                  <div
                    className="consultation-one__contentwrap__content__input-box"
                    bis_skin_checked={1}
                  >
                    <input type="text" placeholder="Full Name" name="name" 
                       onChange={(e) => setcustName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-6" bis_skin_checked={1}>
                  <div
                    className="consultation-one__contentwrap__content__input-box"
                    bis_skin_checked={1}
                  >
                    <input type="text" placeholder="Phone Number" name="phone"
                    onChange={(e) => setCustContact(e.target.value)}
                     />
                  </div>
                </div>
                <div className="col-md-6" bis_skin_checked={1}>
                  <div
                    className="consultation-one__contentwrap__content__input-box"
                    bis_skin_checked={1}
                  >
                    <input
                      type="email"
                      placeholder="Email Address"
                      name="email"
                      onChange={(e) => setCustEmail(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-6" bis_skin_checked={1}>
                  <div
                    className="consultation-one__contentwrap__content__input-box"
                    bis_skin_checked={1}
                  >
                    <input type="text" placeholder="Pin Code" name="pincode"
                    onChange={(e) => setCustPincode(e.target.value)}
                     />
                  </div>
                </div>
              
                <div className="col-md-12" bis_skin_checked={1}>
                  {/* <div
                    className="consultation-one__contentwrap__content__input-box text-message-box"
                    bis_skin_checked={1}
                  >
                    <textarea
                      name="address"
                      placeholder="Address"
                      defaultValue={""}
                    />
                  </div> */}
                  {formError && (
                      <p style={{ color: "red" }}>
                        Please fill in all required fields
                      </p>
                    )}
                  <div
                    className="consultation-one__contentwrap__content__btn-box"
                    bis_skin_checked={1}
                  >
                    <button
                      type="submit"
                      className="wallpi-btn wallpi-btn--submit"
                      onClick={ContactEnquiry}
                    >
                      <span>Send Request</span>
                    </button>
                  </div>
                </div>
              </div>
    </form>

  </Container>
  );
};

export default ContactModal;
