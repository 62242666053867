import service1 from "../Asserts/Images/sk (1).webp";
import wallCvering from "../Asserts/Images/sk (19).webp";
import grassFloor from "../Asserts/Images/sk (4).webp";
import FloorCoverings from "../Asserts/Images/Karndean-Mosaic-Tile-Flooring (1).webp";
import verticalBlinds from "../Asserts/Images/22-500x500.webp";
import glassFilm from "../Asserts/Images/glass-film-500x500.webp";
import wallFlowerPainting from "../Asserts/Images/interior-wall-painting-500x500.webp";
import rubberMat from "../Asserts/Images/cow-mat-500x500.webp";
import FallCeiling from "../Asserts/Images/571447897False-Ceiling-Design(Main).jpg";
import wallWithBaskets from "../Asserts/Images/wall-with-baskets.jpg";
import interior from "../Asserts/Images/interior.webp";
import FurnitureWork from "../Asserts/Images/furtinure.webp";
const serviceData =  [
    
    {
        id:1,
        serviceName:"Wall covering",
        type:"wallpapers",
        EImage: wallCvering,
    },
    {
        id:2,
        serviceName:"Floor Coverings",
        type:"wallpapers",
        EImage: FloorCoverings,
    },
    {
        id:3,
        serviceName:"Vertical blinds",
        type:"wallpapers",
        EImage: verticalBlinds,
    },
    {
        id:4,
        serviceName:"Glass Film",
        type:"wallpapers",
        EImage: glassFilm,
    },
    {
        id:5,
        serviceName:"Artificial Grass",
        type:"wallpapers",
        EImage: grassFloor,
    },
    {
        id:6,
        serviceName:"Wall Flower Garden",
        type:"wallpapers",
        EImage: wallWithBaskets,
    },
    {
        id:7,
        serviceName:"Rabber Mat",
        type:"wallpapers",
        EImage: rubberMat,
    },
    {
        id:8,
        serviceName:"Wall Painting",
        type:"wallpapers",
        EImage: wallFlowerPainting,
    },
    {
        id:9,
        serviceName:"Fall Ceiling",
        type:"wallpapers",
        EImage: FallCeiling,
    },
    {
        id:10,
        serviceName:"Furniture Work",
        type:"wallpapers",
        EImage: FurnitureWork,
    },
    {
        id:11,
        serviceName:"Interiors",
        type:"Interiors",
        EImage: interior,
    },
]

export default serviceData;