import React, { useState } from 'react';
import { Nav, Navbar, Container } from "react-bootstrap";
import hadder from "../Css/Header.css"
// import Logo from "../assets/logo.png"
import { Link } from "react-router-dom";
import Logo from "../Asserts/Images/logo.png";

const Hadder = () => {
  return (
    <>
    <div className="nav-bar-component">
        <section className="NavSection">
            {/* <div className="container"> */}
            <Navbar collapseOnSelect expand="lg" variant="dark" >
                <Container>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Brand href="/">
                        <div className='NavLogo'>
                             <img className='logo-image' src={Logo}  alt=" logo" /> 
                        </div>
                    </Navbar.Brand>
                    <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-start">
                        <Nav>
                            <Nav.Link href="#"> <Link to="/">Home</Link></Nav.Link>
                            <Nav.Link href="#"><Link to="/designer-wallpaper-in-santacruz ">About us </Link></Nav.Link>
                            <Nav.Link href="#"><Link to="/rooms-wallpaper-in-santacruz">Services</Link></Nav.Link>
                            <Nav.Link href="#"><Link to="/glass-flims-wallpaper-service-in-santacruz">Products</Link></Nav.Link>
                            <Nav.Link href="#"><Link to="/3d-wallpaper-for-wall-in-santacruz">Gallery</Link></Nav.Link>
                            <Nav.Link href="#"><Link to="/wallpaper-store-near-santacruz">Contact Us</Link></Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                    <Nav className="Social-media-icons">
                    </Nav>
                </Container>
            </Navbar>
            {/* </div> */}
        </section>

    </div>
</>
  )
}

export default Hadder
