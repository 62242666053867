import ThreeDLiving from "../Asserts/Images/3d-living-room-wallpaper.webp";
import vinylWallpaper from "../Asserts/Images/vinylWallpaper.webp";
import flooring from "../Asserts/Images/why-chooselaminate-flooringl-heading.webp";
import vinylCarpet from "../Asserts/Images/vinylCarpet.webp";
import customized from "../Asserts/Images/customized-wallpaper.webp";
import verticalBlind from "../Asserts/Images/verticalBlind.webp";
import SunControl from "../Asserts/Images/Car-Sun-control-film.webp";
import windowRoller from "../Asserts/Images/window-roller-blinds.webp";

const products = [
    {
        id:1,
        productName: "3D Wallpapers",
        image: ThreeDLiving
    },
    {
        id:2,
        productName: "Vinyl Wallpapers",
        image: vinylWallpaper
    },
    {
        id:3,
        productName: "Wooden Flooring",
        image: flooring
    },
    {
        id:4,
        productName: "Vinyl Carpet",
        image: vinylCarpet
    },
    {
        id:5,
        productName: "Customized Wallper",
        image: customized
    },
    {
        id:6,
        productName: "Vertical Blind",
        image: verticalBlind
    },
    {
        id:7,
        productName: "Sun Control Flim",
        image: SunControl
    },
    {
        id:8,
        productName: "Roller Blinds",
        image: windowRoller
    },
    
]

export default products;