import React from 'react'
import server1 from "../Asserts/Images/sk (1).webp";
import serviceData from "../Asserts/ServiceData";
import MetaTags from 'react-meta-tags';
import '../Css/Services.css'
import { FaArrowRight } from "react-icons/fa6";
// import bannerImage from "../Asserts/Images/hero-image2-1.webp";

const Services = ({bannerImage}) => {
  return (
    <>
    <MetaTags>
      <title>Designer Wallpaper In Santacruz | SK-Wallpaper</title>
        <meta title="Designer Wallpaper In Santacruz | SK-Wallpaper" />
        <meta
          name="description"
          content="Revamp your Santacruz rooms with stunning wallpaper from SK-Wallpaper. Elevate your space with premium designs and impeccable craftsmanship."
        />
        <meta
          name="keywords"
          content="Wallpaper For Walls In Santacruz ,Designer Wallpaper In Santacruz ,Rooms Wallpaper In Santacruz,Glass Flims Wallpaper Service In santacruz,3D Wallpaper For Wall In Santacruz,Wallpaper Store Near Santacruz"
        />
        <link rel="canonical" href="https://skdecors.com/rooms-wallpaper-in-santacruz" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Designer Wallpaper In Santacruz | SK-Wallpaper" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://skdecors.com/rooms-wallpaper-in-santacruz"/>
        <meta property="og:description" content="Revamp your Santacruz rooms with stunning wallpaper from SK-Wallpaper. Elevate your space with premium designs and impeccable craftsmanship." />
        <meta property="og:image" content="https://skdecors.com/static/media/JMS.7493f202fc387288d7c0.png" />       
      </MetaTags>
        <section className="page-header @@ExtraClass">
  <div
    className="page-header__bg"
    style={{
      backgroundImage: `url("${bannerImage}")`
    }}
    bis_skin_checked={1}
  />
  {/* /.page-header__bg */}
  <div className="container" bis_skin_checked={1}>
    <ul className="wallpi-breadcrumb list-unstyled">
      <li>
        <a href="index.html" bis_skin_checked={1}>
          Home
        </a>
      </li>
      <li>
        <span>Services</span>
      </li>
    </ul>
    {/* /.thm-breadcrumb list-unstyled */}
    <h1 className="page-header__title">Rooms Wallpaper In Santacruz </h1>
  </div>
  {/* /.container */}
</section>

<section className="service-one service-one--two service-one--page">

  <div className="container" bis_skin_checked={1}>
  <h2 className='text-center text-black'>Services</h2>
  <p className='text-center'>Explore the world of Rooms Wallpaper In Santacruz and witness how each design tells a unique story. From classic to contemporary, our Rooms Wallpaper In Santacruz options encompass a wide spectrum to suit various styles. We take pride in being the go-to source for Rooms Wallpaper In Santacruz, providing a seamless blend of aesthetics and functionality.

</p>
<p className='d-none'>
Rooms Wallpaper In Santacruz is not just about patterns and designs; it's about creating an ambiance that resonates with your personality. Our commitment to quality is reflected in every roll of Rooms Wallpaper In Santacruz that we offer. As your trusted partner in interior decor, we ensure that Rooms Wallpaper In Santacruz becomes the focal point, transforming your rooms into captivating spaces.

Explore the world of Rooms Wallpaper In Santacruz and witness how each design tells a unique story. From classic to contemporary, our Rooms Wallpaper In Santacruz options encompass a wide spectrum to suit various styles. We take pride in being the go-to source for Rooms Wallpaper In Santacruz, providing a seamless blend of aesthetics and functionality.
</p>
    <div className="row gutter-y-30" bis_skin_checked={1}>
    {serviceData.map((v,i)=>{
        return(<>
            <div className="col-lg-4 col-md-6" bis_skin_checked={1}>
          <div className="project-one__item" bis_skin_checked={1}>
            <div className="project-one__item__image" bis_skin_checked={1}>
              <img
                src={v.EImage}
                alt=" Wallpaper In Santacruz"
              />
            </div>
            <div className="project-one__item__info" bis_skin_checked={1}>
              <div className="project-one__item__inner" bis_skin_checked={1}>
                <div
                  className="project-one__item__bg normal--bg"
                  bis_skin_checked={1}
                >
                  <strong className="project-one__item__text">
                    Wallpapers
                  </strong>
                  <h4 className="project-one__item__heading">
                    <a href="#" bis_skin_checked={1}>
                      {v.serviceName}
                    </a>
                  </h4>
                  {/* <a
                    href="#"
                    className="project-one__item__right-arrow"
                    bis_skin_checked={1}
                  >
                  <FaArrowRight />
                  </a> */}
                </div>
                {/* <div
                  className="project-one__item__bg hover--bg"
                  bis_skin_checked={1}
                >
                  <strong className="project-one__item__text">
                    Wallpapers
                  </strong>
                  <h4 className="project-one__item__heading">
                    <a href="#" bis_skin_checked={1}>
                      Room Wallpapers
                    </a>
                  </h4>
                  <p className="project-one__item__tex-info">
                    There are many variations of passages of lorem Ipsum
                    available, but the majority
                  </p>
                  <a
                    href="#"
                    className="project-one__item__right-arrow"
                    bis_skin_checked={1}
                  >
                  <FaArrowRight />

                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        </>)
      })}
    </div>
  </div>
</section>



    </>
  )
}

export default Services