import React from 'react'
import { Link } from 'react-router-dom';
import '../Css/Footer.css'

const Footer = () => {
  return (
   <>
     <div>
        <footer className="footer-section">
  <div className="container">
    <div className="footer-cta pt-5 pb-5">
      <div className="row">
        <div className="col-lg-5 col-md-6  mb-30">
          <div className="single-cta">
            <i className="fa fa-map-marker" />
            <div className="cta-text pt-2">
              <h4>Find us</h4>
              <span>
              Amardeep Society B/4 Sundar Nagar</span>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 mb-30">
          <div className="single-cta">
            <i className="fa fa-phone" />
            <div className="cta-text pt-2">
              <h4>Call us</h4>
              <span> +91 9870376195  / +91 8097922600 </span>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-12 mb-30">
          <div className="single-cta">
            <i className="fa fa-envelope-open" />
            <div className="cta-text pt-2">
              <h4>Mail us</h4>
              <span>skdecor1968@gmail.com<br/>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="footer-content pt-5 pb-5">
      <div className="row">
        <div className="col-xl-4 col-lg-4 mb-50">
          <div className="footer-widget">
            <div className="footer-logo">
              <a href="index.html">
                {/* <img
                  src={Logo}
                  className="img"
                  alt="logo"
                /> */}
              </a>
            </div>
            <div className="footer-text">
              <p>
              Elevate your space with our exquisite wallpaper collections, where art meets design. Transform your interiors into a canvas of sophistication and style with our curated range. Explore a world of creativity and personalized solutions for a home that reflects your unique taste.
              </p>
            </div>
                <div className="footer-widget">
            {/* <div className="footer-widget-heading">
              <h3></h3>
            </div> */}
          
          
          </div>
           
          </div>
        </div>
        <div className="col-xl-4 col-lg-4 col-md-6 mb-30">
          <div className="footer-widget">
            <div className="footer-widget-heading">
              <h3>Useful Links</h3>
            </div>
            <ul className="m-0 p-0">
              <li>
                <Link to="/">Home</Link>
              </li>
             
             
           
              <li>
                <Link to="/designer-wallpaper-in-santacruz">About Us</Link>
              </li>
             
              <li>
                <Link to="/rooms-wallpaper-in-santacruz">Services</Link>
              </li>
              <li>
                <Link to="/glass-flims-wallpaper-service-in-santacruz">Product</Link>
              </li>


              <li>
                <Link to="/3D-wallpaper-for-wall-in-santacruz">Gallery</Link>
              </li>
              <li>
                <Link to="/wallpaper-store-near-santacruz">Contact Us</Link>
              </li>
            </ul>
          </div>
      
        </div>
        <div className="col-xl-4 col-lg-4 col-md-6 mb-50">
          <div className="footer-widget">
            <div className="footer-widget-heading">
              <h3>Address</h3>
            </div>
            <div className="footer-text mb-25">
              <p>
              Amardeep Society B/4 Sundar Nagar, Road No. 2, Kalina Santacruz (E), Mumbai - 400098
              </p>
            </div>
            {/* <div className="footer-social-icon mt-lg-5">
              <span>Follow us</span>
              <a href="#">
                <i className="fa fa-facebook-f facebook-bg" />
              </a>
              <a href="#">
                <i className="fa fa-twitter twitter-bg" />
              </a>
              <a href="#">
                <i className="fab fa-google-plus-g google-bg" />
              </a>
            </div> */}
          </div>
      
        </div>
      </div>
    </div>
  </div>
  <div className="copyright-area">
    <div className="container">
      <div className="row">
        <div className="col-xl-6 col-lg-6 text-center text-lg-left">
          <div className="copyright-text">
            <p>
              Copyright © 2024, All Right Reserved{" "}
              <br/>
              <a href="/">SK Decor </a>
            </p>
            <p>
              Developed By &nbsp;
              <a href="https://skdm.in/">Shree Krishna Digital Marketing</a>
            </p>
          </div>
        </div>
      
        </div>
      </div>
    </div>
  

</footer>

<div className="floattingButton">
  {/* <a href="#" className="float" id="menu-share">
    <i className="fa fa-link my-float" />
  </a> */}
  <ul>
  <li>
      <a href="https://wa.me/9870376195">
        <i className="fa fa-whatsapp my-float" />
      </a>
    </li>
    <li>
      <a href="https://www.instagram.com/skdecor_wallpaper?utm_source=qr&igshid=ZDc4ODBmNjlmNQ%3D%3D/">
        <i className="fa fa-instagram my-float" />
      </a>
    </li>
    <li className='d-none'>
      <a href="https://facebook.com">
        <i className="fa fa-facebook my-float" />
      </a>
    </li>
  </ul>
</div>
    </div>
   </>
  )
}

export default Footer