import React from 'react'
import server1 from "../Asserts/Images/sk (1).webp";
import serviceData from "../Asserts/ServiceData";
import '../Css/Services.css'
import { FaArrowRight } from "react-icons/fa6";
import products from "../Asserts/Products";
// import bannerImage from "../Asserts/Images/hero-image2-1.webp";
import MetaTags from 'react-meta-tags';

const Products = ({bannerImage}) => {
  return (
    
    <>
     <MetaTags>
      <title>Glass Flims Wallpaper Service In Santacruz | SK-Wallpaper</title>
        <meta title="Glass Flims Wallpaper Service In Santacruz | SK-Wallpaper" />
        <meta
          name="description"
          content="Transform your space with SK-Wallpaper's glass film wallpaper service near Santacruz. Expert craftsmanship ensures effortless elevation of aesthetics."
        />
        <meta
          name="keywords"
          content="Wallpaper For Walls In Santacruz ,Designer Wallpaper In Santacruz ,Rooms Wallpaper In Santacruz,Glass Flims Wallpaper Service In santacruz,3D Wallpaper For Wall In Santacruz,Wallpaper Store Near Santacruz"
        />
        <link rel="canonical" href="https://skdecors.com/glass-flims-wallpaper-service-in-santacruz" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Glass Flims Wallpaper Service In Santacruz | SK-Wallpaper" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://skdecors.com/glass-flims-wallpaper-service-in-santacruz"/>
        <meta property="og:description" content="Transform your space with SK-Wallpaper's glass film wallpaper service near Santacruz. Expert craftsmanship ensures effortless elevation of aesthetics." />
        <meta property="og:image" content="https://skdecors.com/static/media/JMS.7493f202fc387288d7c0.png" />       
      </MetaTags>
        <section className="page-header @@ExtraClass">
        
  <div
    className="page-header__bg"
    style={{
      backgroundImage: `url("${bannerImage}")`
    }}
    bis_skin_checked={1}
  />
  {/* /.page-header__bg */}
  <div className="container" bis_skin_checked={1}>
    <ul className="wallpi-breadcrumb list-unstyled">
      <li>
        <a href="index.html" bis_skin_checked={1}>
          Home
        </a>
      </li>
      <li>
        <span>Product</span>
      </li>
    </ul>
    {/* /.thm-breadcrumb list-unstyled */}
    <h1 className="page-header__title">Glass Flims Wallpaper Service In Santacruz </h1>
  </div>
  {/* /.container */}
        </section>

<section className="service-one service-one--two service-one--page">
  <div className="container" bis_skin_checked={1}>
  <h2 className='text-center text-black'>Products</h2>
  <p className='text-center'>
  Explore the versatility of Glass Films Wallpaper Service In Santacruz, offering a wide range of designs and patterns that enhance both aesthetics and functionality. Our expert team at Glass Films Wallpaper Service In Santacruz is dedicated to providing a personalized experience, ensuring your vision becomes a reality. Whether you seek elegance, privacy, or a combination of both, Glass Films Wallpaper Service In Santacruz has you covered.


</p>
<p className='d-none'>
Glass Films Wallpaper Service In Santacruz is not just about decorating your windows; it's about creating an atmosphere of sophistication and comfort. With our extensive selection, Glass Films Wallpaper Service In Santacruz allows you to express your individuality and style, turning your living or working spaces into a haven of beauty.

Discover the endless possibilities with Glass Films Wallpaper Service In Santacruz as we bring together cutting-edge technology and artistic design. Our commitment is to provide you with the finest Glass Films Wallpaper Service In Santacruz that enhances the ambiance while ensuring privacy. Transform your windows with the elegance and functionality offered by Glass Films Wallpaper Service In Santacruz.


</p>
    <div className="row gutter-y-30" bis_skin_checked={1}>
    {products.map((v,i)=>{
        return(<>
            <div className="col-lg-4 col-md-6" bis_skin_checked={1}>
          <div className="project-one__item" bis_skin_checked={1}>
            <div className="project-one__item__image" bis_skin_checked={1}>
              <img
                src={v.image}
                alt="Glass Flims Wallpaper Service In santacruz"
              />
            </div>
            <div className="project-one__item__info" bis_skin_checked={1}>
              <div className="project-one__item__inner" bis_skin_checked={1}>
                <div
                  className="project-one__item__bg normal--bg"
                  bis_skin_checked={1}
                >
                  <strong className="project-one__item__text">
                    Wallpapers
                  </strong>
                  <h4 className="project-one__item__heading">
                    {/* <a href="#" bis_skin_checked={1}> */}
                      {v.productName}
                    {/* </a> */}
                  </h4>
                  {/* <a
                    href="#"
                    className="project-one__item__right-arrow"
                    bis_skin_checked={1}
                  >
                  <FaArrowRight />

                  <i className="icon-arrow-small-right" />

                    <i className="icon-arrow-small-right" />
                  </a> */}
                </div>
                {/* <div
                  className="project-one__item__bg hover--bg"
                  bis_skin_checked={1}
                >
                  <strong className="project-one__item__text">
                    Wallpapers
                  </strong>
                  <h4 className="project-one__item__heading">
                    <a href="#" bis_skin_checked={1}>
                      Room Wallpapers
                    </a>
                  </h4>
                  <p className="project-one__item__tex-info">
                    There are many variations of passages of lorem Ipsum
                    available, but the majority
                  </p>
                  <a
                    href="#"
                    className="project-one__item__right-arrow"
                    bis_skin_checked={1}
                  >
                  <FaArrowRight />

                    <i className="icon-arrow-small-right" />
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        </>)
      })}
    </div>
  </div>
</section>



    </>
  )
}

export default Products