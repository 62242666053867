import React, { useRef, useState } from "react";
import { FaMapMarkedAlt } from "react-icons/fa";
import { IoIosCall } from "react-icons/io";
import { TbMailCheck } from "react-icons/tb";
import $ from "jquery";
import MetaTags from 'react-meta-tags';
function Contact({ bannerImage }) {
  // email
  const formRef = useRef(null);
  const [custName, setcustName] = useState("");
  const [custContact, setCustContact] = useState("");
  const [custMessage, setCustMessage] = useState("");
  const [custEmail, setCustEmail] = useState("");
  const [custSubject, setCustSubject] = useState("");
  const [formError, setFormError] = useState(false);

  const ContactEnquiry = (e) => {
    e.preventDefault();

    // Validate form fields
    if (
      !custName ||
      !custContact ||
      !custMessage ||
      !custEmail ||
      !custSubject
    ) {
      setFormError(true);
      return;
    }

    // Reset form error state
    setFormError(false);

    // Your form submission logic goes here
    var body = `<!DOCTYPE html><html><head><title>Enquiry Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style="color:#659494">SK Decor</h2><p>Hello</p><p>Please check your enquiry which generated from the website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left"><strong>Name:</strong></td><td style="text-align:left">${custName}</td></tr><tr><td style="text-align:left"><strong>Email:</strong></td><td style="text-align:left">${custEmail}</td></tr><tr><td style="text-align:left"><strong>Contact:</strong></td><td style="text-align:left">${custContact}</td></tr><tr><td style="text-align:left"><strong>Subject:</strong></td><td style="text-align:left">${custSubject}</td></tr><tr><td style="text-align:left;vertical-align:top"><strong>Message:</strong></td><td style="text-align:left">${custMessage}</td></tr></table><p style="font-weight:700">Best regards,<br>Your Team at Sk Decor</p></div></body></html>`;

    $.post(
      "https://skdm.in/server/v1/send_lead_mail.php",
      {
        toEmail: "skdecor1968@gmail.com",
        fromEmail: "skdmlead@gmail.com",
        bccMail: "skdmlead@gmail.com",
        mailSubject: "New Customer Lead",
        mailBody: body,
        accountName: "skdecor",
        accountLeadSource: "https://skdm.in",
        accountLeadName: custName,
      },
      function (dataa, status) {
        console.log("data :" + dataa);
      }
    );

    // Reset the form
    if (formRef.current) {
      formRef.current.reset();
    }

    alert("Your Form has Submitted. Our team will contact you soon.");
  };
  return (
    <>
    <MetaTags>
      <title>Wallpaper Store Near Santacruz | SK-Wallpaper</title>
        <meta title="Wallpaper Store Near Santacruz | SK-Wallpaper" />
        <meta
          name="description"
          content="Explore SK-Wallpaper, your go-to wallpaper store near Santacruz, boasting a wide range of designs to elevate your home decor."
        />
        <meta
          name="keywords"
          content="Wallpaper For Walls In Santacruz ,Designer Wallpaper In Santacruz ,Rooms Wallpaper In Santacruz,Glass Flims Wallpaper Service In santacruz,3D Wallpaper For Wall In Santacruz,Wallpaper Store Near Santacruz"
        />
        <link rel="canonical" href="https://skdecors.com/wallpaper-store-near-santacruz" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Wallpaper Store Near Santacruz | SK-Wallpaper" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://skdecors.com/wallpaper-store-near-santacruz"/>
        <meta property="og:description" content="Explore SK-Wallpaper, your go-to wallpaper store near Santacruz, boasting a wide range of designs to elevate your home decor." />
        <meta property="og:image" content="https://skdecors.com/static/media/JMS.7493f202fc387288d7c0.png" />       
      </MetaTags>
      <section className="page-header contact-page">
        <div
          className="page-header__bg"
          style={{
            backgroundImage: `url(${bannerImage})`,
          }}
          bis_skin_checked={1}
        />
        {/* /.page-header__bg */}
        <div className="container" bis_skin_checked={1}>
          <ul className="wallpi-breadcrumb list-unstyled">
            <li>
              <a href="index.html" bis_skin_checked={1}>
                Home
              </a>
            </li>
            <li>
              <span>Contact</span>
            </li>
          </ul>
          {/* /.thm-breadcrumb list-unstyled */}
          <h1 className="page-header__title">Wallpaper Store Near Santacruz</h1>
        </div>
        {/* /.container */}
      </section>
      <section className="address-one">
      <p className="d-none">
      Welcome to the ultimate destination for interior transformation – your Wallpaper Store Near Santacruz. As the premier Wallpaper Store Near Santacruz, we redefine your living spaces with a curated collection that embodies elegance and style. At Wallpaper Store Near Santacruz, we understand the importance of creating a home that reflects your unique taste.

Explore the diverse range at Wallpaper Store Near Santacruz, where every design tells a story. Our dedicated team at Wallpaper Store Near Santacruz is committed to offering personalized service, ensuring you find the perfect match for your aesthetic preferences. Transform your rooms into captivating havens with the help of Wallpaper Store Near Santacruz.


      </p>
        <div className="container" bis_skin_checked={1}>
          <div className="row" bis_skin_checked={1}>
            <div className="sec-title" bis_skin_checked={1}>
              <h2 className="sec-title__title">
                Please Don't Hesitate To Contact <br /> Us With Any Inquiries
              </h2>
              {/* /.sec-title__title */}
              <p className="sec-title__text">
                You can also reach out to us by phone or email are many
                variations
              </p>
              {/* /.sec-title__text */}
            </div>
            {/* /.sec-title */}
            <div className="address-one__wrap text-center" bis_skin_checked={1}>
              <div className="row gutter-y-20" bis_skin_checked={1}>
                <div
                  className="col-md-4 address-one__colwrap"
                  bis_skin_checked={1}
                >
                  <div className="address-one__col" bis_skin_checked={1}>
                    <span className="address-one__icon icon-map">
                      <FaMapMarkedAlt />
                    </span>
                    <h4 className="address-one__title">Our Office</h4>
                    <p className="address-one__location">
                      Amardeep Society B/4 Sundar Nagar, Road No. 2, Kalina
                      Santacruz (E), Mumbai - 400098
                    </p>
                  </div>
                </div>
                <div
                  className="col-md-4 address-one__colwrap"
                  bis_skin_checked={1}
                >
                  <div className="address-one__col" bis_skin_checked={1}>
                    <span className="address-one__icon icon-phoned">
                      <IoIosCall />
                    </span>
                    <h4 className="address-one__title">Get In Touch</h4>
                    <ul className="list-unstyled address-one__list">
                      <li>
                        <a href="tel:+919870376195" bis_skin_checked={1}>
                          +91 9870376195
                        </a>
                      </li>
                      <li>
                        <a href="tel:+918097922600" bis_skin_checked={1}>
                          +91 8097922600
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="col-md-4 address-one__colwrap"
                  bis_skin_checked={1}
                >
                  <div className="address-one__col" bis_skin_checked={1}>
                    <span className="address-one__icon icon-email1">
                      {" "}
                      <TbMailCheck />
                    </span>
                    <h4 className="address-one__title">Email Address</h4>
                    <ul className="list-unstyled address-one__list">
                      <li>
                        <a
                          href="mailto:skdecor1968@gmail.com"
                          bis_skin_checked={1}
                        >
                          skdecor1968@gmail.com
                        </a>
                      </li>
                      {/* <li>
                  <a href="mailto:Support24@gmail.com" bis_skin_checked={1}>
                    Support24@gmail.com
                  </a>
                </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="contact-one">
        <div className="container-fluid" bis_skin_checked={1}>
          <div className="contact-one__inner" bis_skin_checked={1}>
            <div className="row" bis_skin_checked={1}>
              <div className="col-sm-12" bis_skin_checked={1}>
                <form
                  className="contact-one__form contact-form-validated form-one wow fadeInUp animated"
                  data-wow-duration="1500ms"
                  method="post"
                  ref={formRef}
                  action=""
                  id="contact-form"
                  style={{
                    visibility: "visible",
                    animationDuration: "1500ms",
                    animationName: "fadeInUp",
                  }}
                >
                  <div className="form-one__group" bis_skin_checked={1}>
                    <div className="form-one__control" bis_skin_checked={1}>
                      <input
                        type="text"
                        name="name"
                        placeholder="Your name"
                        onChange={(e) => setcustName(e.target.value)}
                      />
                    </div>
                    {/* /.form-one__control */}
                    <div className="form-one__control" bis_skin_checked={1}>
                      <input
                        type="email"
                        name="email"
                        placeholder="Email Address"
                        onChange={(e) => setCustEmail(e.target.value)}
                      />
                    </div>
                    {/* /.form-one__control */}
                    <div className="form-one__control" bis_skin_checked={1}>
                      <input
                        type="text"
                        name="phone"
                        placeholder="Phone"
                        onChange={(e) => setCustContact(e.target.value)}
                      />
                    </div>
                    {/* /.form-one__control */}
                    <div className="form-one__control" bis_skin_checked={1}>
                      <input
                        type="text"
                        name="subject"
                        placeholder="Subject"
                        onChange={(e) => setCustSubject(e.target.value)}
                      />
                    </div>
                    {/* /.form-one__control */}
                    <div
                      className="form-one__control form-one__control--full"
                      bis_skin_checked={1}
                    >
                      <textarea
                        name="message"
                        placeholder="Your Message here"
                        defaultValue={""}
                        onChange={(e) => setCustMessage(e.target.value)}
                      />
                    </div>
                    {/* /.form-one__control */}
                    {formError && (
                      <p style={{ color: "red" }}>
                        Please fill in all required fields
                      </p>
                    )}
                    <div
                      className="form-one__control form-one__control--full"
                      bis_skin_checked={1}
                    >
                      <button
                        type="submit"
                        className="wallpi-btn wallpi-btn--submit"
                        onClick={ContactEnquiry}
                      >
                        <span>Send A Message</span>
                      </button>
                    </div>
                    {/* /.form-one__control */}
                  </div>
                  {/* /.form-one__group */}
                </form>
                <div className="result" bis_skin_checked={1} />
              </div>
              {/* /.col-xl-5 */}
            </div>
            {/* /.row */}
          </div>
          {/* /.contact-one__inner */}
        </div>
        {/* /.container */}
      </section>
      <section className="contact-map p-3 mb-1">
        <div className="container-fluid" bis_skin_checked={1}>
          <div className="google-map google-map__contact" bis_skin_checked={1}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15082.118883789235!2d72.8599712!3d19.0844036!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c964c5863961%3A0x94453f8877b310b!2sWallpapers%20sk%20decor.-%20Wallpaper%20In%20Bkc%7C%20Wallpaper%20interior%20work%20in%20Andheri%7C%20Carpet%20Shop%20in%20Vile%20Parle!5e0!3m2!1sen!2sin!4v1707718551655!5m2!1sen!2sin"
              // width={600}
              // height={450}
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />

            {/* <iframe
        title="template google map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4562.753041141002!2d-118.80123790098536!3d34.152323469614075!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80e82469c2162619%3A0xba03efb7998eef6d!2sCostco+Wholesale!5e0!3m2!1sbn!2sbd!4v1562518641290!5m2!1sbn!2sbd"
        className="map__contact"
        allowFullScreen=""
      /> */}
          </div>
        </div>
        {/* /.container-fluid */}
      </section>
    </>
  );
}

export default Contact;
