import React from 'react'
import '../Css/About.css'
import MetaTags from 'react-meta-tags';
import { BsPrinter } from "react-icons/bs";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { IoIosCheckmark } from "react-icons/io";
import { FaPaintRoller } from "react-icons/fa6";
import { IoFlowerOutline } from "react-icons/io5";
import { FaHandsHoldingCircle } from "react-icons/fa6";
import { FaCheckSquare } from "react-icons/fa";
import { PiUsersThreeFill } from "react-icons/pi";
import { GrGallery } from "react-icons/gr";
import { LuFolderCheck } from "react-icons/lu";
import { Link } from "react-router-dom";
import featureCircleOne from "../Asserts/cost-effective/feature-circle1-1.jpg";
import featureCircleTwo from "../Asserts/cost-effective/feature-circle1-2.jpg"
import featureOne from "../Asserts/cost-effective/feature1-1.jpg"
import featureTwo from "../Asserts/cost-effective/feature1-2.jpg"


import aboutImg1 from "../Asserts/Images/sk (3).webp";
import aboutImg2 from "../Asserts/Images/sk (18).webp";
import aboutImg3 from "../Asserts/Images/sk (25).webp";
import TestimonialSlider from './TestimonialSlider';
import trustedClient from "../Asserts/Images/sk (17).webp";
const About = ({bannerImage}) => {
  return (
   <>
      <MetaTags>
      <title>Designer Wallpaper In Santacruz | SK-Wallpaper</title>
        <meta title="Designer Wallpaper In Santacruz | SK-Wallpaper" />
        <meta
          name="description"
          content="Luxury living with exquisite wallpaper near Santacruz at SK-Wallpaper. Elevate your space with premium designs and craftsmanship."
        />
        <meta
          name="keywords"
          content="Wallpaper For Walls In Santacruz ,Designer Wallpaper In Santacruz ,Rooms Wallpaper In Santacruz,Glass Flims Wallpaper Service In santacruz,3D Wallpaper For Wall In Santacruz,Wallpaper Store Near Santacruz"
        />
        <link rel="canonical" href="https://skdecors.com/designer-wallpaper-in-santacruz" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Designer Wallpaper In Santacruz | SK-Wallpaper" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://skdecors.com/designer-wallpaper-in-santacruz"/>
        <meta property="og:description" content="Luxury living with exquisite wallpaper near Santacruz at SK-Wallpaper. Elevate your space with premium designs and craftsmanship." />
        <meta property="og:image" content="https://skdecors.com/static/media/JMS.7493f202fc387288d7c0.png" />       
      </MetaTags>
    <section className="page-header @@ExtraClass">
  <div
    className="page-header__bg"
    style={{
      backgroundImage: `url(${bannerImage})`
    }}
    bis_skin_checked={1}
  />
  {/* /.page-header__bg */}
  <div className="container" bis_skin_checked={1}>
    <ul className="wallpi-breadcrumb list-unstyled">
      <li>
        <a href="index.html" bis_skin_checked={1}>
          Home
        </a>
      </li>
      <li>
        <span>About us</span>
      </li>
    </ul>
    {/* /.thm-breadcrumb list-unstyled */}
    <h1 className="page-header__title">Designer Wallpaper In Santacruz </h1>
  </div>
  {/* /.container */}
</section>
    {/* <!-- /.page-header --> */}
    <section className="about-two about-two--aboutpage">
  <div className="container" bis_skin_checked={1}>
    <div className="row about-two__row" bis_skin_checked={1}>
      <div
        className="about-two__imagecol wow fadeInRight animated"
        data-wow-delay="300ms"
        style={{
          visibility: "visible",
          animationDelay: "300ms",
          animationName: "fadeInRight"
        }}
        bis_skin_checked={1}
      >
        <div className="about-two__imagecol__image" bis_skin_checked={1}>
          <img src={aboutImg3} alt=" Wallpaper In Santacruz " />
        </div>
        <div className="about-two__imagecol__imagesmall" bis_skin_checked={1}>
          <img src={aboutImg2} alt="Designer In Santacruz " />
        </div>
      </div>
      <div
        className="about-two__contentcol wow fadeInLeft animated"
        data-wow-delay="300ms"
        style={{
          visibility: "visible",
          animationDelay: "300ms",
          animationName: "fadeInLeft"
        }}
        bis_skin_checked={1}
      >
        <div className="sec-title" bis_skin_checked={1}>
          <span className="sec-title__tagline">About Comapny</span>
          {/* /.sec-title__tagline */}
          <h2 className="sec-title__title">
            Why Choose <br /> Our Wallpaper <br /> Services
          </h2>
          {/* /.sec-title__title */}
          <p className="sec-title__text">
          Elevate your space with designer wallpaper in Santacruz. Our collection blends style and sophistication, offering unique patterns and textures that transform your walls into works of art
          </p>
          {/* /.sec-title__text */}
        </div>
        {/* /.sec-title */}
        <div
          className="about-two__contentcol__benefits d-flex align-items-center"
          bis_skin_checked={1}
        >
          <div
            className="about-two__contentcol__benefits__wrap d-flex align-items-center"
            bis_skin_checked={1}
          >
            <span className="benefits-icon icon-print">
            <BsPrinter />

            </span>

            <p className="about-two__contentcol__benefits__text">
              Quality Standards Product
            </p>
          </div>
          <div
            className="about-two__contentcol__benefits__wrap d-flex align-items-center"
            bis_skin_checked={1}
          >
            <span className="benefits-icon icon-done-ring-round">
            <IoMdCheckmarkCircleOutline />

            </span>
            <p className="about-two__contentcol__benefits__text">
              Cost Effective Services
            </p>
          </div>
        </div>
        <p className="about-two__contentcol__text">
        Transform your living space with Designer Wallpaper in Santacruz. Our curated collection seamlessly combines style and sophistication, presenting distinctive patterns and textures that turn your walls into captivating works of art.
        </p>
        <div className="about-two__contentcol__btnwrap" bis_skin_checked={1}>
        <Link to='/3D-wallpaper-for-wall-in-santacruz'>
        <a
            href="#"
            className="wallpi-btn about-one--btn"
            bis_skin_checked={1}
          >
            <span>Explore more</span>
          </a>
        </Link>
          
          <h6 className="about-two__contentcol__printed">
          {/* <label><IoIosCheckmark /></label> */}

            High Quality Printed Wallpaper
          </h6>
        </div>
      </div>
      {/* /.why-choose-two__image */}
    </div>
    {/* /.row */}
  </div>
  {/* /.container */}
    </section>
    {/* <!-- about-two End --> */}
    <section className="feature-two feature-two--aboutpage">
  <div className="container" bis_skin_checked={1}>
    <div className="row" bis_skin_checked={1}>
      <div
        className="col-lg-4 wow fadeInUp animated"
        data-wow-delay="100ms"
        style={{
          visibility: "visible",
          animationDelay: "100ms",
          animationName: "fadeInUp"
        }}
        bis_skin_checked={1}
      >
        <div className="feature-two__innercol text-center" bis_skin_checked={1}>
          <div className="feature-two__icon" bis_skin_checked={1}>
            <i className="icon-wallpaper-1" >  <FaPaintRoller /></i>
           

          </div>
          <h4 className="feature-two__title">We’re Effective</h4>
          <p className="feature-two__text">
          For cost-effective, high-quality color printing, choose us. We deliver exceptional results without compromising your budget or standards
          </p>
        </div>
      </div>
      <div
        className="col-lg-4 wow fadeInUp animated"
        data-wow-delay="200ms"
        style={{
          visibility: "visible",
          animationDelay: "200ms",
          animationName: "fadeInUp"
        }}
        bis_skin_checked={1}
      >
        <div className="feature-two__innercol text-center" bis_skin_checked={1}>
          <div className="feature-two__icon" bis_skin_checked={1}>
            <i className="icon-mobile" > <IoFlowerOutline /></i>
            

          </div>
          <h4 className="feature-two__title">We’re Careful</h4>
          <p className="feature-two__text">
          We're careful in every detail, ensuring precision and thoroughness in our services. Your satisfaction and trust matter most
          </p>
        </div>
      </div>
      <div
        className="col-lg-4 wow fadeInUp animated"
        data-wow-delay="300ms"
        style={{
          visibility: "visible",
          animationDelay: "300ms",
          animationName: "fadeInUp"
        }}
        bis_skin_checked={1}
      >
        <div className="feature-two__innercol text-center" bis_skin_checked={1}>
          <div className="feature-two__icon" bis_skin_checked={1}>
            <i className="icon-management" ><FaHandsHoldingCircle /></i>
          </div>
          <h4 className="feature-two__title">We’re Flexible</h4>
          <p className="feature-two__text">
          We're flexible and adaptable, tailoring solutions to meet your needs. Our commitment is to provide seamless, customized experiences
          </p>
        </div>
      </div>
    </div>
  </div>
    </section>
    <section className="why-choose-one why-choose-one--aboutpage">
  <div className="why-choose-one--aboutpage__shapeleft" bis_skin_checked={1}>
    {/* <img src="assets/images/shapes/about-shap-left1-1.png" alt="" /> */}
  </div>
  {/* <div className="why-choose-one--aboutpage__shaperight" bis_skin_checked={1}>
    <img src="assets/images/shapes/about-shap-right1-1.png" alt="Wallpaper In Santacruz " />
  </div> */}
  <div className="container" bis_skin_checked={1}>
    <div className="row" bis_skin_checked={1}>
      <div className="col-lg-6 col-md-12" bis_skin_checked={1}>
        <div className="why-choose-one__content" bis_skin_checked={1}>
          <div className="sec-title" bis_skin_checked={1}>
            <span className="sec-title__tagline">Why Should Choose Us</span>
            {/* /.sec-title__tagline */}
            <h2 className="sec-title__title">
              We Provide Quality Home Wallpaper Solution
            </h2>
            {/* /.sec-title__title */}
            <p className="sec-title__text">
            Discover the diverse selection of Designer Wallpaper in Santacruz. Our range caters to various tastes, ensuring a harmonious fusion of style and elegance in your living spaces.
</p>
            {/* /.sec-title__text */}
          </div>
          {/* /.sec-title */}
          <Link to="/glass-flims-wallpaper-service-in-santacruz">
          <a
            href="#"
            className="wallpi-btn why-choose-one--btn"
            bis_skin_checked={1}
          >
            <span>Explore more</span>
          </a>
          </Link>
          
        </div>
      </div>
      <div className="col-lg-6 col-md-12" bis_skin_checked={1}>
        <div className="why-choose-one__feature" bis_skin_checked={1}>
          <div className="feature-one__item" bis_skin_checked={1}>
            <div className="feature-one__item__img" bis_skin_checked={1}>
              <img src={featureTwo} alt="Designer In Santacruz " />
            </div>
            <div
              className="feature-one__item__wrap d-flex flex-wrap"
              bis_skin_checked={1}
            >
              <div
                className="feature-one__item__circle_img"
                bis_skin_checked={1}
              >
                <img
                  src={featureCircleTwo}
                  alt="Designer In Santacruz "
                />
              </div>
              <div className="feature-one__item__info" bis_skin_checked={1}>
                <h4 className="feature-one__item__info__title">
                  100% Your Satisfaction
                </h4>
                <p className="feature-one__item__info__text">
                Our commitment is 100% your satisfaction, providing unparalleled service, exceeding expectations, and ensuring a delightful experience every time
                </p>
              </div>
            </div>
          </div>
          {/* feature-item */}
          <div className="feature-one__item" bis_skin_checked={1}>
            <div className="feature-one__item__img" bis_skin_checked={1}>
              <img src={featureOne} alt="Designer In Santacruz " />
            </div>
            <div
              className="feature-one__item__wrap d-flex flex-wrap"
              bis_skin_checked={1}
            >
              <div
                className="feature-one__item__circle_img"
                bis_skin_checked={1}
              >
                <img
                  src={featureCircleOne}
                  alt="Designer In Santacruz "
                />
              </div>
              <div className="feature-one__item__info" bis_skin_checked={1}>
                <h4 className="feature-one__item__info__title">
                  Cost Effective Services
                </h4>
                <p className="feature-one__item__info__text">
                Wallpaper for walls in Santacruz emphasizes cost-effective solutions, prioritizing efficiency within budget constraints, ensuring maximum value for clients seeking optimal outcomes
                </p>
              </div>
            </div>
          </div>
          {/* feature-item */}
        </div>
      </div>
    </div>
  </div>
</section>
<section className="funfact-one funfact-one--aboutpage">
  <div className="container" bis_skin_checked={1}>
    <ul className="list-unstyled funfact-one__list">
      <li className="funfact-one__item count-box counted">
        <div className="funfact-one__content text-center" bis_skin_checked={1}>
          <i className="funfact-one__icon icon-img-box" >
          <GrGallery />
          </i>
          <div className="funfact-one__count-wrap" bis_skin_checked={1}>
            <strong
              className="funfact-one__count counter-text count-text"
              data-stop={15}
              data-speed={1500}
            >
              15
            </strong>
          </div>
          <p className="funfact-one__text counter-text">Years Of Experience</p>
        </div>
      </li>
      {/* /.funfact-one__item */}
      <li className="funfact-one__item count-box counted">
        <div className="funfact-one__content text-center" bis_skin_checked={1}>
          <i className="funfact-one__icon icon-book-check"> <FaCheckSquare /></i>
          <div className="funfact-one__count-wrap" bis_skin_checked={1}>
            <strong
              className="funfact-one__count counter-text count-text"
              data-stop={10}
              data-speed={1500}
            >
              10
            </strong>
            <span className="funfact-one__counter-num counter-text">K+</span>
          </div>
          <p className="funfact-one__text counter-text">Customers Satisfaction</p>
        </div>
      </li>
      {/* /.funfact-one__item */}
      <li className="funfact-one__item count-box counted">
        <div className="funfact-one__content text-center" bis_skin_checked={1}>
          <i className="funfact-one__icon icon-folder-check" > <LuFolderCheck /> </i>
          <div className="funfact-one__count-wrap" bis_skin_checked={1}>
            <strong
              className="funfact-one__count count-text counter-text"
              data-stop={14}
              data-speed={1500}
            >
              14
            </strong>
            <span className="funfact-one__counter-num counter-text">K+</span>
          </div>
          <p className="funfact-one__text counter-text">Projects </p>
        </div>
      </li>
      {/* /.funfact-one__item */}
      <li className="funfact-one__item count-box counted">
        <div className="funfact-one__content text-center" bis_skin_checked={1}>
          <i className="funfact-one__icon icon-user"> <PiUsersThreeFill /> </i>
          <div className="funfact-one__count-wrap" bis_skin_checked={1}>
            <strong
              className="funfact-one__count count-text counter-text"
              data-stop={11}
              data-speed={1500}
            >
              11
            </strong>
            <span className="funfact-one__counter-num counter-text">K+</span>
          </div>
          <p className="funfact-one__text counter-text">Our Recent Client</p>
        </div>
      </li>
      {/* /.funfact-one__item */}
    </ul>
    {/* /.list-unstyled funfact-one__list */}
  </div>
  {/* /.container */}
</section>

<section
  className="services-feedback-one services-feedback-one--two services-feedback-one--aboutpage"
  style={{
    backgroundImage:
      'url("assets/images/resources/services-feedback-bg2-1.jpg")'
  }}
>
  <div className="container" bis_skin_checked={1}>
    <div className="row services-feedback-one__row" bis_skin_checked={1}>
      <div
        className="services-feedback-one__content fadeInLeft"
        data-wow-delay="100ms"
        bis_skin_checked={1}
      >
        <div className="sec-title" bis_skin_checked={1}>
          <h2 className="sec-title__title">
            Leading Provider of interior Service In Mumbai
          </h2>
          {/* /.sec-title__title */}
          <p className="sec-title__text">
          As the leading provider of interior services in Mumbai, we excel in transforming spaces with innovative designs, impeccable execution, and a commitment to exceeding your expectations
          </p>
          {/* /.sec-title__text */}
        </div>
        {/* /.sec-title */}
        <div
          className="services-feedback-one__progress-wrap"
          bis_skin_checked={1}
        >
          <div className="services-feedback-one__progress" bis_skin_checked={1}>
            <h6 className="services-feedback-one__progress-title">
              Our Success Client
            </h6>
            <div
              className="services-feedback-one__progress-bar"
              bis_skin_checked={1}
            >
              <div
                className="services-feedback-one__progress-inner count-bar counted"
                data-percent="85%"
                bis_skin_checked={1}
                style={{ width: "85%" }}
              >
                <div
                  className="services-feedback-one__progress-number count-text"
                  bis_skin_checked={1}
                >
                  85%
                </div>
              </div>
            </div>
          </div>
          <div className="services-feedback-one__progress" bis_skin_checked={1}>
            <h6 className="services-feedback-one__progress-title">
              Interior Work
            </h6>
            <div
              className="services-feedback-one__progress-bar"
              bis_skin_checked={1}
            >
              <div
                className="services-feedback-one__progress-inner count-bar counted"
                data-percent="90%"
                bis_skin_checked={1}
                style={{ width: "90%" }}
              >
                <div
                  className="services-feedback-one__progress-number count-text"
                  bis_skin_checked={1}
                >
                  90%
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="services-feedback-one__image fadeInRight"
        data-wow-delay="100ms"
        bis_skin_checked={1}
      >
        <img
          className="services-feedback-one__mainimage"
          src={aboutImg1}
          alt="Designer Wallpaper "
        />
        {/* <div className="services-feedback-one__leftshape" bis_skin_checked={1}>
          <img src={aboutImg3} alt="" />
        </div>
        <div className="services-feedback-one__rightshape" bis_skin_checked={1}>
          <img src={aboutImg3} alt="" />
        </div> */}
      </div>
    </div>
  </div>
</section>
<section className="services-client-one">
  <div className="container" bis_skin_checked={1}>
    <div className="row" bis_skin_checked={1}>
      <div
        className="col-sm-12 wow fadeInUp animated"
        data-wow-delay="100ms"
        style={{
          visibility: "visible",
          animationDelay: "100ms",
          animationName: "fadeInUp"
        }}
        bis_skin_checked={1}
      >
        <div
          className="services-client-one__wrap d-flex align-items-center justify-content-sm-between"
          bis_skin_checked={1}
        >
          <div className="services-client-one__image" bis_skin_checked={1}>
            <img src={trustedClient} alt="Designer  In Santacruz " />
          </div>
          <h4 className="services-client-one__title">
            We’re Trusted by More then 8800 Clients
          </h4>
          <Link to='/glass-flims-wallpaper-service-in-santacruz'>
          <a
            href="#"
            className="wallpi-btn services-client--btn"
            bis_skin_checked={1}
          >
            <span>Explore more</span>
          </a>
          </Link>
          
        </div>
      </div>
    </div>
  </div>
</section>
<section className="mb-0 testimonials-one testimonials-one--three testimonials-one--aboutpage">
  <div className="container" bis_skin_checked={1}>
    <div className="row" bis_skin_checked={1}>
      <div className="col-md-12" bis_skin_checked={1}>
        <div className="sec-title" bis_skin_checked={1}>
          <span className="sec-title__tagline">Testimonial</span>
          {/* /.sec-title__tagline */}
          <h2 className="sec-title__title">
            What Does The Customer <br /> Have To Say?
          </h2>
          {/* /.sec-title__title */}
        </div>
        {/* /.sec-title */}
      </div>
    </div>
    <div>
        <TestimonialSlider />
    </div>
    {/* /.testimonials-one__carousel */}
  </div>
  {/* /.container */}
</section>







   </>
  )
}

export default About