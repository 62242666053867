import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import serviceData from "../Asserts/ServiceData";
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import './styles.css';

// import required modules
import { Pagination } from 'swiper/modules';

const ServiceSlider = () => {
  return (
    <>
        <Swiper
        slidesPerView={1}
        spaceBetween={10}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 50,
          },
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
      {serviceData.map((v,i)=>{
        return(<>
            <SwiperSlide>
            <div className=" wow fadeInUp animated"
          data-wow-delay="100ms"
          style={{
            visibility: "visible",
            animationDelay: "100ms",
            animationName: "fadeInUp",
            marginBottom: "50px",
          }}
          bis_skin_checked={1}
        >
          <div className="service-one__item" bis_skin_checked={1}>
            <div className="service-one__item__image" bis_skin_checked={1}>
              <img
                src={v.EImage}
                alt="services"
              />
              <div className="service-one__item__icon" bis_skin_checked={1}>
                <i className="icon-varnish" />
              </div>
            </div>
            <div className="service-one__item__info" bis_skin_checked={1}>
              <a
                className="sercives-arrow-right"
                href="services-wall-painting.html"
                bis_skin_checked={1}
              >
                <i className="icon-arrow-small-right" />
              </a>
              <h4 className="service-one__item__title">
                {/* <a href="#" bis_skin_checked={1}> */}
                  {v.serviceName}
                {/* </a> */}
              </h4>
              <p className="service-one__item__text">
                Explore our print solutions &amp; services
              </p>
            </div>
          </div>
        </div>
            </SwiperSlide>
        </>)
      })}
      </Swiper>
    </>
  )
}

export default ServiceSlider