import React, {useRef, useState} from "react";
import $ from "jquery";
import MetaTags from 'react-meta-tags';
import "../Css/Home.css";
import { Button, Modal } from "react-bootstrap";
import featureCircleOne from "../Asserts/cost-effective/feature-circle1-1.jpg";
import featureCircleTwo from "../Asserts/cost-effective/feature-circle1-2.jpg";
import featureOne from "../Asserts/cost-effective/feature1-1.jpg";
import featureTwo from "../Asserts/cost-effective/feature1-2.jpg";
import quality1 from "../Asserts/Images/sk (3).webp";
import quality2 from "../Asserts/Images/sk (25).webp";
import ServiceSlider from "../Pages/ServiceSlider";
import service from "../Asserts/Images/sk (19).webp";
import trustedClient from "../Asserts/Images/sk (17).webp";
import contactImg1 from "../Asserts/Images/consultation-img1-1.webp";
import contactImg2 from "../Asserts/Images/consultation-img1-2.webp";
import contactImg3 from "../Asserts/Images/hero-image1-1.webp";
import projectData from "../Component/ProjectData";
import banner1 from "../Asserts/Images/manBanner(3).webp";
import banner2 from "../Asserts/Images/manBanner(2).webp";
import banner3 from "../Asserts/Images/manBanner(1).webp";
import { Swiper, SwiperSlide } from "swiper/react";
import { IoMdCall } from "react-icons/io";
import 'swiper/css/navigation';
// import { Navigation } from 'swiper/modules';
import { FaArrowRight } from "react-icons/fa6";
import { GrGallery } from "react-icons/gr";
import { FaCheckSquare } from "react-icons/fa";
import { PiUsersThreeFill } from "react-icons/pi";
import { LuFolderCheck } from "react-icons/lu";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
import Footer from "../Component/Footer";
import TestimonialSlider from "../Pages/TestimonialSlider";
import ContactModal from "../Pages/ContactModal";
import { Link } from "react-router-dom";

const Home = () => {
  const [modalIsOpen, setModalIsOpen] = useState(true);

  const closeModal = () => {
    setModalIsOpen(false);
  };
  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
 // email
 const formRef = useRef(null);
 const [custName, setcustName] = useState("");
 const [custContact, setCustContact] = useState("");
 const [custMessage, setCustMessage] = useState("");
 const [custEmail, setCustEmail] = useState("");
 const [custSubject, setCustSubject] = useState("");
 const [formError, setFormError] = useState(false);

 const ContactEnquiry = (e) => {
   e.preventDefault();

   // Validate form fields
   if (!custName || !custMessage || !custEmail ) {
     setFormError(true);
     return;
   }

   // Reset form error state
   setFormError(false);

   // Your form submission logic goes here
   var body = `<!DOCTYPE html><html><head><title>Enquiry Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style="color:#659494">SK Decor</h2><p>Hello</p><p>Please check your enquiry which generated from the website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left"><strong>Name:</strong></td><td style="text-align:left">${custName}</td></tr><tr><td style="text-align:left"><strong>Email:</strong></td><td style="text-align:left">${custEmail}</td></tr><tr><td style="text-align:left"><strong>Contact:</strong></td><td style="text-align:left">${custContact}</td></tr><tr><td style="text-align:left"><strong>Subject:</strong></td><td style="text-align:left">${custSubject}</td></tr><tr><td style="text-align:left;vertical-align:top"><strong>Message:</strong></td><td style="text-align:left">${custMessage}</td></tr></table><p style="font-weight:700">Best regards,<br>Your Team at Sk Decor</p></div></body></html>`;

   $.post(
     "https://skdm.in/server/v1/send_lead_mail.php",
     {
       toEmail: "skdecor1968@gmail.com",
       fromEmail: "skdmlead@gmail.com",
       bccMail: "skdmlead@gmail.com",
       mailSubject: "New Customer Lead",
       mailBody: body,
       accountName: "skdecor",
       accountLeadSource: "https://skdm.in",
       accountLeadName: custName,
     },
     function (dataa, status) {
       console.log("data :" + dataa);
     }
   );

   // Reset the form
   if (formRef.current) {
     formRef.current.reset();
   }

   alert("Your Form has Submitted. Our team will contact you soon.");

 };
  return (
    <>
    <MetaTags>
      <title>Wallpaper For Walls In Santacruz | SK-Wallpaper</title>
        <meta title="Wallpaper For Walls In Santacruz | SK-Wallpaper" />
        <meta
          name="description"
          content="Transform your Santacruz space with premium wallpaper for walls in Santacruz from SK-Wallpaper. Elevate your decor effortlessly."
        />
        <meta
          name="keywords"
          content="Wallpaper For Walls In Santacruz ,Designer Wallpaper In Santacruz ,Rooms Wallpaper In Santacruz,Glass Flims Wallpaper Service In santacruz,3D Wallpaper For Wall In Santacruz,Wallpaper Store Near Santacruz"
        />
        <link rel="canonical" href="https://skdecors.com/" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Wallpaper For Walls In Santacruz | SK-Wallpaper" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://skdecors.com/"/>
        <meta property="og:description" content="Transform your Santacruz space with premium wallpaper for walls in Santacruz from SK-Wallpaper. Elevate your decor effortlessly." />
        <meta property="og:image" content="https://skdecors.com/static/media/JMS.7493f202fc387288d7c0.png" />       
      </MetaTags>
      <div className="page-wrapper" bis_skin_checked={1}>
        <div>
          {/* <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button> */}
          <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
              <Modal.Title>Enquiry Now</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ContactModal />
            </Modal.Body>
          </Modal>
        </div>

        {/* /.main-header */}
        {/* main-slider-start */}
        {/* <section className="main-slider-one">
    <div
      className="main-slider-one__carousel wallpi-owl__carousel owl-carousel owl-loaded owl-drag"
      data-owl-options='{
		"loop": true,
		"animateOut": "fadeOut",
		"animateIn": "fadeIn",
		"items": 1,
		"autoplay": false,
		"autoplayTimeout": 7000,
		"smartSpeed": 1000,
		"nav": true,
  "navText": ["<span class=\"icon-left-arrow1\"></span>","<span class=\"icon-right-arrow1\"></span>"],
		"dots": false,
		"margin": 0
		}'
      bis_skin_checked={1}
    >
      <div className="owl-stage-outer" bis_skin_checked={1}>
        <div
          className="owl-stage"
          style={{
            transform: "translate3d(-3328px, 0px, 0px)",
            transition: "all 0s ease 0s",
            width: 9984
          }}
          bis_skin_checked={1}
        >
          <div
            className="owl-item cloned"
            style={{ width: 1664 }}
            bis_skin_checked={1}
          >
            <div className="item" bis_skin_checked={1}>
              <div className="main-slider-one__item" bis_skin_checked={1}>
                <div
                  className="main-slider-one__bg"
                  style={{
                    backgroundImage:
                      "url(assets/images/backgrounds/hero-image1-1.jpg)"
                  }}
                  bis_skin_checked={1}
                />
                <div className="container" bis_skin_checked={1}>
                  <div className="row" bis_skin_checked={1}>
                    <div className="col-md-12" bis_skin_checked={1}>
                      <div
                        className="main-slider-one__content"
                        bis_skin_checked={1}
                      >
                        <span className="main-slider-one__tag-title">
                          We Are Wallpi
                        </span>
                        <h1 className="main-slider-one__title">
                          Quality <br /> Wallpaper <br /> Solution{" "}
                        </h1>
                        <div
                          className="main-slider-one__btn"
                          bis_skin_checked={1}
                        >
                          <a
                            href="services.html"
                            className="wallpi-btn hero-btn"
                            bis_skin_checked={1}
                          >
                            <span>View Our Service</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="main-slider-one__shope" bis_skin_checked={1}>
                  <img src="assets/images/shapes/hero-icon1-1.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div
            className="owl-item cloned"
            style={{ width: 1664 }}
            bis_skin_checked={1}
          >
            <div className="item" bis_skin_checked={1}>
              <div className="main-slider-one__item" bis_skin_checked={1}>
                <div
                  className="main-slider-one__bg"
                  style={{
                    backgroundImage:
                      "url(assets/images/backgrounds/hero-image1-2.jpg)"
                  }}
                  bis_skin_checked={1}
                />
                <div className="container" bis_skin_checked={1}>
                  <div className="row" bis_skin_checked={1}>
                    <div className="col-md-12" bis_skin_checked={1}>
                      <div
                        className="main-slider-one__content"
                        bis_skin_checked={1}
                      >
                        <span className="main-slider-one__tag-title">
                          We Are Wallpi
                        </span>
                        <h1 className="main-slider-one__title">
                          Quality <br /> Wallpaper <br /> Solution{" "}
                        </h1>
                        <div
                          className="main-slider-one__btn"
                          bis_skin_checked={1}
                        >
                          <a
                            href="services.html"
                            className="wallpi-btn hero-btn"
                            bis_skin_checked={1}
                          >
                            <span>View Our Service</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="owl-item active"
            style={{ width: 1664 }}
            bis_skin_checked={1}
          >
            <div className="item" bis_skin_checked={1}>
              <div className="main-slider-one__item" bis_skin_checked={1}>
                <div
                  className="main-slider-one__bg"
                  style={{
                    backgroundImage:
                      "url(assets/images/backgrounds/hero-image1-1.jpg)"
                  }}
                  bis_skin_checked={1}
                />
                <div className="container" bis_skin_checked={1}>
                  <div className="row" bis_skin_checked={1}>
                    <div className="col-md-12" bis_skin_checked={1}>
                      <div
                        className="main-slider-one__content"
                        bis_skin_checked={1}
                      >
                        <span className="main-slider-one__tag-title">
                          We Are Wallpi
                        </span>
                        <h1 className="main-slider-one__title">
                          Quality <br /> Wallpaper <br /> Solution{" "}
                        </h1>
                        <div
                          className="main-slider-one__btn"
                          bis_skin_checked={1}
                        >
                          <a
                            href="services.html"
                            className="wallpi-btn hero-btn"
                            bis_skin_checked={1}
                          >
                            <span>View Our Service</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="main-slider-one__shope" bis_skin_checked={1}>
                  <img src="assets/images/shapes/hero-icon1-1.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div
            className="owl-item"
            style={{ width: 1664 }}
            bis_skin_checked={1}
          >
            <div className="item" bis_skin_checked={1}>
              <div className="main-slider-one__item" bis_skin_checked={1}>
                <div
                  className="main-slider-one__bg"
                  style={{
                    backgroundImage:
                      "url(assets/images/backgrounds/hero-image1-2.jpg)"
                  }}
                  bis_skin_checked={1}
                />
                <div className="container" bis_skin_checked={1}>
                  <div className="row" bis_skin_checked={1}>
                    <div className="col-md-12" bis_skin_checked={1}>
                      <div
                        className="main-slider-one__content"
                        bis_skin_checked={1}
                      >
                        <span className="main-slider-one__tag-title">
                          We Are Wallpi
                        </span>
                        <h1 className="main-slider-one__title">
                          Quality <br /> Wallpaper <br /> Solution{" "}
                        </h1>
                        <div
                          className="main-slider-one__btn"
                          bis_skin_checked={1}
                        >
                          <a
                            href="services.html"
                            className="wallpi-btn hero-btn"
                            bis_skin_checked={1}
                          >
                            <span>View Our Service</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="owl-item cloned"
            style={{ width: 1664 }}
            bis_skin_checked={1}
          >
            <div className="item" bis_skin_checked={1}>
              <div className="main-slider-one__item" bis_skin_checked={1}>
                <div
                  className="main-slider-one__bg"
                  style={{
                    backgroundImage:
                      "url(assets/images/backgrounds/hero-image1-1.jpg)"
                  }}
                  bis_skin_checked={1}
                />
                <div className="container" bis_skin_checked={1}>
                  <div className="row" bis_skin_checked={1}>
                    <div className="col-md-12" bis_skin_checked={1}>
                      <div
                        className="main-slider-one__content"
                        bis_skin_checked={1}
                      >
                        <span className="main-slider-one__tag-title">
                          We Are Wallpi
                        </span>
                        <h1 className="main-slider-one__title">
                          Quality <br /> Wallpaper <br /> Solution{" "}
                        </h1>
                        <div
                          className="main-slider-one__btn"
                          bis_skin_checked={1}
                        >
                          <a
                            href="services.html"
                            className="wallpi-btn hero-btn"
                            bis_skin_checked={1}
                          >
                            <span>View Our Service</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="main-slider-one__shope" bis_skin_checked={1}>
                  <img src="assets/images/shapes/hero-icon1-1.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div
            className="owl-item cloned"
            style={{ width: 1664 }}
            bis_skin_checked={1}
          >
            <div className="item" bis_skin_checked={1}>
              <div className="main-slider-one__item" bis_skin_checked={1}>
                <div
                  className="main-slider-one__bg"
                  style={{
                    backgroundImage:
                      "url(assets/images/backgrounds/hero-image1-2.jpg)"
                  }}
                  bis_skin_checked={1}
                />
                <div className="container" bis_skin_checked={1}>
                  <div className="row" bis_skin_checked={1}>
                    <div className="col-md-12" bis_skin_checked={1}>
                      <div
                        className="main-slider-one__content"
                        bis_skin_checked={1}
                      >
                        <span className="main-slider-one__tag-title">
                          We Are Wallpi
                        </span>
                        <h1 className="main-slider-one__title">
                          Quality <br /> Wallpaper <br /> Solution{" "}
                        </h1>
                        <div
                          className="main-slider-one__btn"
                          bis_skin_checked={1}
                        >
                          <a
                            href="services.html"
                            className="wallpi-btn hero-btn"
                            bis_skin_checked={1}
                          >
                            <span>View Our Service</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="owl-nav" bis_skin_checked={1}>
        <button
          type="button"
          role="presentation"
          className="owl-prev"
          aria-label="carousel button"
        >
          <span className="icon-left-arrow1" />
        </button>
        <button
          type="button"
          role="presentation"
          className="owl-next"
          aria-label="carousel button"
        >
          <span className="icon-right-arrow1" />
        </button>
      </div>
      <div className="owl-dots disabled" bis_skin_checked={1} />
    </div>
  </section> */}
        <section>
          <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
            <SwiperSlide>
              <div className="main-slider-one__item" bis_skin_checked={1}>
                <div
                  className="main-slider-one__bg"
                  style={{
                    backgroundImage: `url("${banner1}")`,
                  }}
                  bis_skin_checked={1}
                />
                <div className="container" bis_skin_checked={1}>
                  <div className="row" bis_skin_checked={1}>
                    <div className="col-md-12" bis_skin_checked={1}>
                      <div
                        className="main-slider-one__content"
                        bis_skin_checked={1}
                      >
                        <span className="main-slider-one__tag-title">
                          We SK Decor
                        </span>
                        <h1 className="main-slider-one__title">
                          
                         Wallpaper options <br /> available for <br /> walls in Santacruz 
                          {/* Quality <br /> Wallpaper <br /> Solution{" "} */}
                        </h1>
                        <div
                          className="main-slider-one__btn"
                          bis_skin_checked={1}
                        >
                          <Link to='/rooms-wallpaper-in-santacruz'>
                          <a
                            href="/rooms-wallpaper-in-santacruz"
                            className="wallpi-btn hero-btn"
                            bis_skin_checked={1}
                          >
                            <span>View Our Service</span>
                          </a>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="main-slider-one__shope" bis_skin_checked={1}>
                  <img src={contactImg2} alt="" />
                </div> */}
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="main-slider-one__item" bis_skin_checked={1}>
                <div
                  className="main-slider-one__bg"
                  style={{
                    backgroundImage: `url("${banner2}")`,
                  }}
                  bis_skin_checked={1}
                />
                <div className="container" bis_skin_checked={1}>
                  <div className="row" bis_skin_checked={1}>
                    <div className="col-md-12" bis_skin_checked={1}>
                      <div
                        className="main-slider-one__content"
                        bis_skin_checked={1}
                      >
                        <span className="main-slider-one__tag-title">
                          We SK Decor
                        </span>
                        <h2 className="main-slider-one__title">
                          solutions
                         High-quality <br/>  Wallpaper <br/>  Solution{" "}
                        </h2>
                        <div
                          className="main-slider-one__btn"
                          bis_skin_checked={1}
                        >
                          <Link to='/rooms-wallpaper-in-santacruz'>
                          <a
                            href="/rooms-wallpaper-in-santacruz"
                            className="wallpi-btn hero-btn"
                            bis_skin_checked={1}
                          >
                            <span>View Our Service</span>
                          </a>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="main-slider-one__shope" bis_skin_checked={1}>
                  <img src={contactImg2} alt="" />
                </div> */}
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="main-slider-one__item" bis_skin_checked={1}>
                <div
                  className="main-slider-one__bg"
                  style={{
                    backgroundImage: `url("${banner3}")`,
                  }}
                  bis_skin_checked={1}
                />
                <div className="container" bis_skin_checked={1}>
                  <div className="row" bis_skin_checked={1}>
                    <div className="col-md-12" bis_skin_checked={1}>
                      <div
                        className="main-slider-one__content"
                        bis_skin_checked={1}
                      >
                        <span className="main-slider-one__tag-title">
                          We SK Decor
                        </span>
                        <h2 className="main-slider-one__title">
                          Quality <br /> Wallpaper <br /> Solution{" "}
                        </h2>
                        <div
                          className="main-slider-one__btn"
                          bis_skin_checked={1}
                        >
                           <Link to='/rooms-wallpaper-in-santacruz'>
                          <a
                            href="/rooms-wallpaper-in-santacruz"
                            className="wallpi-btn hero-btn"
                            bis_skin_checked={1}
                          >
                            <span>View Our Service</span>
                          </a>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="main-slider-one__shope" bis_skin_checked={1}>
                  <img src={contactImg2} alt="" />
                </div> */}
              </div>
            </SwiperSlide>
          </Swiper>
        </section>
        {/* main-slider-end */}
        {/* Feature Start */}
        <section className="feature-one">
          <div className="container" bis_skin_checked={1}>
            <div className="row feature-one__row" bis_skin_checked={1}>
              <div
                className="col-lg-6 feature-one__col wow fadeInUp animated"
                data-wow-delay="100ms"
                style={{
                  visibility: "visible",
                  animationDelay: "100ms",
                  animationName: "fadeInUp",
                }}
                bis_skin_checked={1}
              >
                <div className="feature-one__item" bis_skin_checked={1}>
                  <div className="feature-one__item__img" bis_skin_checked={1}>
                    <img src={featureOne} alt="wallpi" />
                  </div>
                  <div
                    className="feature-one__item__wrap d-flex flex-wrap"
                    bis_skin_checked={1}
                  >
                    <div
                      className="feature-one__item__circle_img"
                      bis_skin_checked={1}
                    >
                      <img src={featureCircleOne} alt="" />
                    </div>
                    <div
                      className="feature-one__item__info"
                      bis_skin_checked={1}
                    >
                      <h4 className="feature-one__item__info__title">
                        Cost Effective Services
                      </h4>
                      <p className="feature-one__item__info__text">
                      Wallpaper for walls in Santacruz emphasizes cost-effective solutions, prioritizing efficiency within budget constraints, ensuring maximum value for clients seeking optimal outcomes
                      </p>
                    </div>
                  </div>
                </div>
                {/* feature-item */}
              </div>
              <div
                className="col-lg-6 feature-one__col wow fadeInUp animated"
                data-wow-delay="200ms"
                style={{
                  visibility: "visible",
                  animationDelay: "200ms",
                  animationName: "fadeInUp",
                }}
                bis_skin_checked={1}
              >
                <div className="feature-one__item" bis_skin_checked={1}>
                  <div className="feature-one__item__img" bis_skin_checked={1}>
                    <img src={featureTwo} alt="wallpi" />
                  </div>
                  <div
                    className="feature-one__item__wrap d-flex flex-wrap"
                    bis_skin_checked={1}
                  >
                    <div
                      className="feature-one__item__circle_img"
                      bis_skin_checked={1}
                    >
                      <img src={featureCircleTwo} alt="" />
                    </div>
                    <div
                      className="feature-one__item__info"
                      bis_skin_checked={1}
                    >
                      <h4 className="feature-one__item__info__title">
                        100% Your Satisfaction
                      </h4>
                      <p className="feature-one__item__info__text">
                      Our commitment is 100% your satisfaction, providing unparalleled service, exceeding expectations, and ensuring a delightful experience every time                      </p>
                    </div>
                  </div>
                </div>
                {/* feature-item */}
              </div>
            </div>
          </div>
        </section>
        {/* Feature End */}
        <section className="about-one">
          <div className="container" bis_skin_checked={1}>
            <div className="sec-title" bis_skin_checked={1}>
              <span className="sec-title__tagline">Why Should Choose Us</span>
              {/* /.sec-title__tagline */}
              <h2 className="sec-title__title">
                We Provide Quality Home <br /> Wallpaper Solution
              </h2>
              {/* /.sec-title__title */}
            </div>
            {/* /.sec-title */}
            <div className="row" bis_skin_checked={1}>
              <div
                className="col-lg-6 wow fadeInRight animated"
                data-wow-delay="300ms"
                style={{
                  visibility: "visible",
                  animationDelay: "300ms",
                  animationName: "fadeInRight",
                }}
                bis_skin_checked={1}
              >
                <div className="about-one__content" bis_skin_checked={1}>
                  <div className="about-one__content__col" bis_skin_checked={1}>
                    <h3 className="about-one__content__titel">
                      Quality Wallpaper Solution
                    </h3>
                    <p className="about-one__content__text">
                    Explore the extensive collection of Wallpaper for Walls in Santacruz. Our varied offerings cater to diverse tastes, ensuring a perfect blend of style and elegance for your living spaces
                    </p>
                  </div>
                  <div className="about-one__content__col" bis_skin_checked={1}>
                    <h3 className="about-one__content__titel">
                      Unique Wall Designs
                    </h3>
                    <p className="about-one__content__text">
                    Discover the unparalleled sophistication of Unique Wall Designs. Elevate your space with our exclusive creations, showcasing innovative aesthetics that redefine interior elegance and captivate every observer                    </p>
                  </div>
                  <Link to="/glass-flims-wallpaper-service-in-santacruz">
                    <a
                      href="/glass-flims-wallpaper-service-in-santacruz"
                      className="wallpi-btn about-one--btn"
                      bis_skin_checked={1}
                    >
                      <span>Explore more</span>
                    </a>
                  </Link>
                </div>
              </div>
              {/* /.col-lg-6 */}
              <div className="col-lg-6" bis_skin_checked={1}>
                <div
                  className="about-one__image wow fadeInLeft animated"
                  data-wow-delay="300ms"
                  style={{
                    visibility: "visible",
                    animationDelay: "300ms",
                    animationName: "fadeInLeft",
                  }}
                  bis_skin_checked={1}
                >
                  <div className="about-one__image__wrap" bis_skin_checked={1}>
                    <img
                      className="about-one__image__wrap__main"
                      src={quality1}
                      alt="wallpi"
                    />
                    <div
                      className="about-one__image__wrap__inner_wrap"
                      bis_skin_checked={1}
                    >
                      <div
                        className="about-one__image__wrap__inner_mx"
                        bis_skin_checked={1}
                      >
                        <img
                          className="about-one__image__wrap__inner_img"
                          src={quality2}
                          alt="wallpi"
                        />
                        <div
                          className="about-one__image__wrap__inner_shape-top"
                          bis_skin_checked={1}
                        />
                        <div
                          className="about-one__image__wrap__inner_shape-left"
                          bis_skin_checked={1}
                        />
                        {/* <div
                    className="about-one__image__wrap__circle"
                    bis_skin_checked={1}
                  >
                    <img
                      src="assets/images/shapes/about-icon1-1.png"
                      alt="wallpi"
                    />
                  </div> */}
                      </div>
                    </div>
                  </div>
                </div>
                {/* /.why-choose-two__image */}
              </div>
              {/* /.col-lg-6 */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container */}
        </section>
        {/* /.about-one */}
        {/* Service Start */}
        <section className="service-one">
          <div
            className="service-one__bg"
            style={{
              backgroundImage:
                'url("assets/images/backgrounds/services-bg1-1.jpg")',
            }}
            bis_skin_checked={1}
          />
          <div className="container" bis_skin_checked={1}>
            <div className="row" bis_skin_checked={1}>
              <div className="col-md-12" bis_skin_checked={1}>
                <div
                  className="service-one__sec-title-wrap d-flex flex-wrap pt-5"
                  bis_skin_checked={1}
                >
                  <div className="sec-title" bis_skin_checked={1}>
                    {/* <span className="sec-title__tagline" style={{color:"#fff !important"}}>Our Services</span> */}
                    {/* /.sec-title__tagline */}
                    <h2 className="sec-title__title">
                      Services That <br /> We Provide
                    </h2>
                    {/* /.sec-title__title */}
                  </div>
                  {/* /.sec-title */}
                  <div
                    className="service-one__sec-info-wrap"
                    bis_skin_checked={1}
                  >
                    <p className="service-one__sec-info-text">
                    Discover our exceptional services in wallpaper—installation, customization, and expert guidance. Elevate your space with our dedicated solutions and expertise
                    </p>
                    <Link to="/designer-wallpaper-in-santacruz">
                    <a
                      href="services.html"
                      className="wallpi-btn service-one--btn"
                      bis_skin_checked={1}
                    >
                      <span
                        style={{ background: "#fff !important", color: "#000" }}
                      >
                        Explore more
                      </span>
                    </a>
                    </Link>
                   
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row  gutter-y-20" bis_skin_checked={1}>
                <ServiceSlider />

                {/* <div
          className="col-lg-3 col-md-6 wow fadeInUp animated"
          data-wow-delay="100ms"
          style={{
            visibility: "visible",
            animationDelay: "100ms",
            animationName: "fadeInUp"
          }}
          bis_skin_checked={1}
        >
          <div className="service-one__item" bis_skin_checked={1}>
            <div className="service-one__item__image" bis_skin_checked={1}>
              <img
                src="assets/images/resources/services1-2.jpg"
                alt="services"
              />
              <div className="service-one__item__icon" bis_skin_checked={1}>
                <i className="icon-wallpaper-3" />
              </div>
            </div>
            <div className="service-one__item__info" bis_skin_checked={1}>
              <a
                className="sercives-arrow-right"
                href="services-room-wallpaper.html"
                bis_skin_checked={1}
              >
                <i className="icon-arrow-small-right" />
              </a>
              <h4 className="service-one__item__title">
                <a href="services-room-wallpaper.html" bis_skin_checked={1}>
                  Room Wallpapers
                </a>
              </h4>
              <p className="service-one__item__text">
                Explore our print solutions &amp; services
              </p>
            </div>
          </div>
        </div> */}
                {/* <div
          className="col-lg-3 col-md-6 wow fadeInUp animated"
          data-wow-delay="100ms"
          style={{
            visibility: "visible",
            animationDelay: "100ms",
            animationName: "fadeInUp"
          }}
          bis_skin_checked={1}
        >
          <div className="service-one__item" bis_skin_checked={1}>
            <div className="service-one__item__image" bis_skin_checked={1}>
              <img
                src="assets/images/resources/services1-3.jpg"
                alt="services"
              />
              <div className="service-one__item__icon" bis_skin_checked={1}>
                <i className="icon-interior-design" />
              </div>
            </div>
            <div className="service-one__item__info" bis_skin_checked={1}>
              <a
                className="sercives-arrow-right"
                href="services-interior-design.html"
                bis_skin_checked={1}
              >
                <i className="icon-arrow-small-right" />
              </a>
              <h4 className="service-one__item__title">
                <a href="services-interior-design.html" bis_skin_checked={1}>
                  Interior Designing
                </a>
              </h4>
              <p className="service-one__item__text">
                Explore our print solutions &amp; services
              </p>
            </div>
          </div>
        </div> */}
                {/* <div
          className="col-lg-3 col-md-6 wow fadeInUp animated"
          data-wow-delay="100ms"
          style={{
            visibility: "visible",
            animationDelay: "100ms",
            animationName: "fadeInUp"
          }}
          bis_skin_checked={1}
        >
          <div className="service-one__item" bis_skin_checked={1}>
            <div className="service-one__item__image" bis_skin_checked={1}>
              <img
                src="assets/images/resources/services1-4.jpg"
                alt="services"
              />
              <div className="service-one__item__icon" bis_skin_checked={1}>
                <i className="icon-wallpaper-2" />
              </div>
            </div>
            <div className="service-one__item__info" bis_skin_checked={1}>
              <a
                className="sercives-arrow-right"
                href="services-details.html"
                bis_skin_checked={1}
              >
                <i className="icon-arrow-small-right" />
              </a>
              <h4 className="service-one__item__title">
                <a href="services-details.html" bis_skin_checked={1}>
                  Digital Foiling
                </a>
              </h4>
              <p className="service-one__item__text">
                Explore our print solutions &amp; services
              </p>
            </div>
          </div>
        </div> */}
              </div>
            </div>
          </div>
        </section>
        {/* Service End */}
        <section className="services-feedback-one">
          <div
            className="services-feedback-one__bg"
            style={{
              backgroundImage: 'url("assets/images/resources/feature1-2.jpg")',
            }}
            bis_skin_checked={1}
          />
          <div
            className="services-feedback-one__shapeleft"
            style={{
              backgroundImage:
                'url("assets/images/shapes/feedback-shape1-2.png")',
            }}
            bis_skin_checked={1}
          />
          <div
            className="services-feedback-one__shaperight"
            style={{
              backgroundImage:
                'url("assets/images/shapes/feedback-shape1-3.png")',
            }}
            bis_skin_checked={1}
          />
          <div className="container" bis_skin_checked={1}>
            <div
              className="row services-feedback-one__row"
              bis_skin_checked={1}
            >
              <div
                className="col-lg-7 wow fadeInLeft animated"
                data-wow-delay="100ms"
                style={{
                  visibility: "visible",
                  animationDelay: "100ms",
                  animationName: "fadeInLeft",
                }}
                bis_skin_checked={1}
              >
                <div
                  className="services-feedback-one__content"
                  bis_skin_checked={1}
                >
                  <div className="sec-title" bis_skin_checked={1}>
                    <h2 className="sec-title__title">
                      Leading Provider Of interior Service In Mumbai
                    </h2>
                    {/* /.sec-title__title */}
                    <p className="sec-title__text">
                    As the leading provider of interior services in Mumbai, we excel in transforming spaces. Our expertise encompasses design, execution, and innovation, ensuring your vision comes to life seamlessly
                    </p>
                  </div>
                  <div
                    className="services-feedback-one__progress-wrap"
                    bis_skin_checked={1}
                  >
                    <div
                      className="services-feedback-one__progress"
                      bis_skin_checked={1}
                    >
                      <h6 className="services-feedback-one__progress-title">
                        Our Success Client
                      </h6>
                      <div
                        className="services-feedback-one__progress-bar"
                        bis_skin_checked={1}
                      >
                        <div
                          className="services-feedback-one__progress-inner count-bar counted"
                          data-percent="85%"
                          bis_skin_checked={1}
                          style={{ width: "85%" }}
                        >
                          <div
                            className="services-feedback-one__progress-number count-text"
                            bis_skin_checked={1}
                          >
                            85%
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="services-feedback-one__progress"
                      bis_skin_checked={1}
                    >
                      <h6 className="services-feedback-one__progress-title">
                        Interior Work
                      </h6>
                      <div
                        className="services-feedback-one__progress-bar"
                        bis_skin_checked={1}
                      >
                        <div
                          className="services-feedback-one__progress-inner count-bar counted"
                          data-percent="90%"
                          bis_skin_checked={1}
                          style={{ width: "90%" }}
                        >
                          <div
                            className="services-feedback-one__progress-number count-text"
                            bis_skin_checked={1}
                          >
                            90%
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-5 wow fadeInRight animated"
                data-wow-delay="100ms"
                style={{
                  visibility: "visible",
                  animationDelay: "100ms",
                  animationName: "fadeInRight",
                }}
                bis_skin_checked={1}
              >
                <div
                  className="services-feedback-one__image"
                  bis_skin_checked={1}
                >
                  <img src={service} alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* /.services-feedback-one */}
        <section className="services-client-one">
          <div className="container" bis_skin_checked={1}>
            <div className="row" bis_skin_checked={1}>
              <div
                className="col-sm-12 wow fadeInUp animated"
                data-wow-delay="100ms"
                style={{
                  visibility: "visible",
                  animationDelay: "100ms",
                  animationName: "fadeInUp",
                }}
                bis_skin_checked={1}
              >
                <div
                  className="services-client-one__wrap d-flex align-items-center justify-content-sm-between"
                  bis_skin_checked={1}
                >
                  <div
                    className="services-client-one__image"
                    bis_skin_checked={1}
                  >
                    <img src={trustedClient} alt="" />
                  </div>
                  <h4 className="services-client-one__title">
                    We’re Trusted By More Then 8800 Clients
                  </h4>
                  <Link to="/designer-wallpaper-in-santacruz">
                  <a
                    href="services.html"
                    className="wallpi-btn services-client--btn"
                    bis_skin_checked={1}
                  >
                    <span>Explore more</span>
                  </a>
                  </Link>
                 
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* /.services-client-one */}
        <section className="project-one">
          <div className="container" bis_skin_checked={1}>
            <div className="sec-title" bis_skin_checked={1}>
              <span className="sec-title__tagline">Our Project</span>
              {/* /.sec-title__tagline */}
              <h2 className="sec-title__title">Our Quality Work</h2>
              {/* /.sec-title__title */}
            </div>
            {/* /.sec-title */}
            <div
              className="row project-one__row gutter-y-20"
              bis_skin_checked={1}
              style={{ padding: "0px 25px" }}
            >
              <Swiper
                slidesPerView={1}
                spaceBetween={10}
                pagination={{
                  clickable: true,
                }}
                breakpoints={{
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 40,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 50,
                  },
                }}
                modules={[Pagination]}
                className="mySwiper"
              >
                {projectData.map((v, i) => {
                  return (
                    <>
                      <SwiperSlide>
                        <div className="" bis_skin_checked={1} style={{paddingBottom:"45px"}}>
                          <div
                            className="project-one__item"
                            bis_skin_checked={1}
                          >
                            <div
                              className="project-one__item__image"
                              bis_skin_checked={1}
                            >
                              <img src={v.img} alt="project" />
                            </div>
                            <div
                              className="project-one__item__info"
                              bis_skin_checked={1}
                            >
                              <div
                                className="project-one__item__inner"
                                bis_skin_checked={1}
                              >
                                <div
                                  className="project-one__item__bg normal--bg"
                                  bis_skin_checked={1}
                                >
                                  <strong className="project-one__item__text">
                                    Wallpapers
                                  </strong>
                                  <h4 className="project-one__item__heading">
                                    <a href="#" bis_skin_checked={1}>
                                      Room Wallpapers
                                    </a>
                                  </h4>
                                  <a
                                    href="portfolio-details.html"
                                    className="project-one__item__right-arrow"
                                    bis_skin_checked={1}
                                  >
                                    <FaArrowRight />
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    </>
                  );
                })}
              </Swiper>
            </div>
          </div>
        </section>
        {/* /.project-one */}
        <section className="funfact-one">
          <div className="container" bis_skin_checked={1}>
            <div
              className="funfact-one__bg"
              style={{
                backgroundImage:
                  'url("assets/images/backgrounds/funfact-bg1-1.jpg")',
              }}
              bis_skin_checked={1}
            />
            <ul className="list-unstyled funfact-one__list">
              <li className="funfact-one__item count-box counted">
                <div
                  className="funfact-one__content text-center"
                  bis_skin_checked={1}
                >
                  <i className="funfact-one__icon icon-img-box">
                    {" "}
                    <GrGallery />{" "}
                  </i>
                  <div className="funfact-one__count-wrap" bis_skin_checked={1}>
                    <strong
                      className="funfact-one__count count-text"
                      data-stop={15}
                      data-speed={1500}
                    >
                      15
                    </strong>
                  </div>
                  <p className="funfact-one__text">Years Of Experience</p>
                </div>
              </li>
              {/* /.funfact-one__item */}
              <li className="funfact-one__item count-box counted">
                <div
                  className="funfact-one__content text-center"
                  bis_skin_checked={1}
                >
                  <i className="funfact-one__icon icon-book-check">
                    {" "}
                    <FaCheckSquare />{" "}
                  </i>
                  <div className="funfact-one__count-wrap" bis_skin_checked={1}>
                    <strong
                      className="funfact-one__count count-text"
                      data-stop={10}
                      data-speed={1500}
                    >
                      10
                    </strong>
                    <span className="funfact-one__counter-num">K+</span>
                  </div>
                  <p className="funfact-one__text">Customers Satisfaction</p>
                </div>
              </li>
              {/* /.funfact-one__item */}
              <li className="funfact-one__item count-box counted">
                <div
                  className="funfact-one__content text-center"
                  bis_skin_checked={1}
                >
                  <i className="funfact-one__icon icon-folder-check">
                    {" "}
                    <LuFolderCheck />{" "}
                  </i>
                  <div className="funfact-one__count-wrap" bis_skin_checked={1}>
                    <strong
                      className="funfact-one__count count-text"
                      data-stop={14}
                      data-speed={1500}
                    >
                      14
                    </strong>
                    <span className="funfact-one__counter-num">K+</span>
                  </div>
                  <p className="funfact-one__text">Projects Worldwide</p>
                </div>
              </li>
              {/* /.funfact-one__item */}
              <li className="funfact-one__item count-box counted">
                <div
                  className="funfact-one__content text-center"
                  bis_skin_checked={1}
                >
                  <i className="funfact-one__icon icon-user">
                    {" "}
                    <PiUsersThreeFill />{" "}
                  </i>
                  <div className="funfact-one__count-wrap" bis_skin_checked={1}>
                    <strong
                      className="funfact-one__count count-text"
                      data-stop={11}
                      data-speed={1500}
                    >
                      11
                    </strong>
                    <span className="funfact-one__counter-num">K+</span>
                  </div>
                  <p className="funfact-one__text">Our Recent Client</p>
                </div>
              </li>
              {/* /.funfact-one__item */}
            </ul>
            {/* /.list-unstyled funfact-one__list */}
          </div>
          {/* /.container */}
        </section>
        {/* /.funfact-one */}
        <section className="testimonials-one">
          <div className="container" bis_skin_checked={1}>
            <div className="row" bis_skin_checked={1}>
              <div className="col-md-12" bis_skin_checked={1}>
                <div className="sec-title" bis_skin_checked={1}>
                  <span className="sec-title__tagline">Testimonial</span>
                  {/* /.sec-title__tagline */}
                  <h2 className="sec-title__title">
                    What Does The Customer <br /> Have To Say?
                  </h2>
                </div>
              </div>
            </div>
            <TestimonialSlider />
          </div>
        </section>
        <section className="consultation-one">
          <div
            className="consultation-one__bg"
            style={{
              backgroundImage:
                'url("assets/images/shapes/consultation-shape1-1.png")',
            }}
            bis_skin_checked={1}
          />
          <div
            className="container-fluid consultation-one__wrap"
            bis_skin_checked={1}
          >
            <div className="row consultation-one__row" bis_skin_checked={1}>
              <div className="consultation-one__colimg" bis_skin_checked={1}>
                <div
                  className="consultation-one__colimg__image"
                  bis_skin_checked={1}
                >
                  <div
                    className="consultation-one__colimg__image__mainimg"
                    bis_skin_checked={1}
                  >
                    <img src={contactImg3} alt="wallpi" />
                  </div>
                  <div
                    className="consultation-one__colimg__image__circlemediam"
                    bis_skin_checked={1}
                  >
                    <img src={contactImg2} alt="wallpi" />
                  </div>
                  <div
                    className="consultation-one__colimg__image__circlesmall"
                    bis_skin_checked={1}
                  >
                    <img src={contactImg1} alt="wallpi" />
                  </div>
                </div>
              </div>
              <div
                className="consultation-one__contentwrap"
                bis_skin_checked={1}
              >
                <div
                  className="consultation-one__contentwrap__bg"
                  style={{
                    backgroundImage:
                      'url("assets/images/backgrounds/consultation-bg1-1.png")',
                  }}
                  bis_skin_checked={1}
                />
                <div
                  className="consultation-one__contentwrap__content"
                  bis_skin_checked={1}
                >
                  <form
                    className="contact__form contact-form-validated"
                    ref={formRef}
                    // action="inc/sendemail.php"
                    noValidate="novalidate"
                  >
                    <div className="sec-title" bis_skin_checked={1}>
                      <h2 className="sec-title__title">
                        Get A Free Consultation
                      </h2>
                      {/* /.sec-title__title */}
                      <p className="sec-title__text">
                      Unlock possibilities with our services. Get a free consultation today and <br /> discover personalized solutions that cater to your unique needs
                        
                      </p>
                      {/* /.sec-title__text */}
                    </div>
                    {/* /.sec-title */}
                    <div className="row" bis_skin_checked={1}>
                      <div className="col-md-6" bis_skin_checked={1}>
                        <div
                          className="consultation-one__contentwrap__content__input-box"
                          bis_skin_checked={1}
                        >
                          <input
                            type="text"
                            placeholder="Full Name"
                            name="name"
                            onChange={(e) => setcustName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6" bis_skin_checked={1}>
                        <div
                          className="consultation-one__contentwrap__content__input-box"
                          bis_skin_checked={1}
                        >
                          <input
                            type="email"
                            placeholder="Email Address"
                            name="email"
                            onChange={(e) => setCustEmail(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-12" bis_skin_checked={1}>
                        <div
                          className="consultation-one__contentwrap__content__input-box text-message-box"
                          bis_skin_checked={1}
                        >
                          <textarea
                            name="message"
                            placeholder="Message Details"
                            defaultValue={""}
                            onChange={(e) => setCustMessage(e.target.value)}
                          />
                        </div>
                        {formError && (
                      <p style={{ color: "red" }}>
                        Please fill in all required fields
                      </p>
                    )}
                        <div
                          className="consultation-one__contentwrap__content__btn-box"
                          bis_skin_checked={1}
                        >
                          <button
                            type="submit"
                            className="wallpi-btn wallpi-btn--submit"
                            onClick={ContactEnquiry}
                          >
                            <span>Send Request</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div
                  className="consultation-one__contentwrap__call"
                  bis_skin_checked={1}
                >
                  <div
                    className="consultation-one__contentwrap__inner"
                    bis_skin_checked={1}
                  >
                    <i className="icon-phone-call">
                      {" "}
                      <IoMdCall />{" "}
                    </i>
                    <p className="consultation-one__contentwrap__telnumber">
                      Call Anytime{" "}
                      <a href="tel:+88(000)-4612" bis_skin_checked={1}>
                      +91 9870376195
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* /.consultation-one */}

        {/* /.blog-one blog-one--page */}

        {/* /.quick-support */}

        {/* /.main-footer */}
      </div>
    </>
  );
};

export default Home;
